@import '~styles/mixins';

.post {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  max-width: rem(650px);
  animation-name: appearing;
  animation-duration: 2s;

  @keyframes appearing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .postImgWrapperTrending {
    position: relative;
    overflow: hidden;
    // min-height: rem(350px);
    // height: rem(600px);
    border-radius: rem(25px);
    // aspect-ratio: 10 / 8;
    aspect-ratio: 1;

    @media (max-width: rem(768px)) {
      // height: rem(500px);
      border-radius: rem(15px);
      // min-height: rem(200px);
    }

    @media (max-width: rem(390px)) {
      // height: rem(400px);
      border-radius: rem(15px);
      // min-height: rem(200px);
      // aspect-ratio: 10 / 9;
    }

    @media (max-width: rem(320px)) {
      // height: rem(350px);
      border-radius: rem(15px);
      // min-height: rem(300px);
    }

    .postImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .postImgWrapper {
    position: relative;
    overflow: hidden;
    // min-height: rem(350px);
    // height: rem(600px);
    border-radius: rem(25px);
    // aspect-ratio: 10 / 8;
    // aspect-ratio: 1;

    @media (max-width: rem(768px)) {
      // height: rem(500px);
      border-radius: rem(15px);
      // min-height: rem(200px);
    }

    @media (max-width: rem(390px)) {
      // height: rem(400px);
      border-radius: rem(15px);
      // min-height: rem(200px);
      // aspect-ratio: 10 / 9;
    }

    @media (max-width: rem(320px)) {
      // height: rem(350px);
      border-radius: rem(15px);
      // min-height: rem(300px);
    }

    .postImg {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: rem(950px)) {
    margin-top: rem(15px);
  }

  &Bottom {
    // padding: rem(13px) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    .arrows {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leftArrow,
      .rightArrow {
        position: relative;
        width: rem(50px);
        height: rem(50px);

        .icon {
          width: 100%;
          height: 100%;
          fill: rgb(255, 255, 255);
        }
      }
      .link {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .textAndCopy {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      .text {
        font-weight: 500;
        font-size: rem(15px);
        line-height: 150%;
        max-width: 90%;

        &Style {
          position: relative;
          border: 1px solid hsla(295, 100%, 42%, 1);
          border-radius: rem(20px);
          padding: rem(2px);
        }

        @media (max-width: rem(400px)) {
          font-size: rem(12px);
          line-height: rem(15px);
        }
      }

      .postCopy {
        width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        fill: white;
        object-fit: contain;

        @media (max-width: rem(400px)) {
          width: rem(30px);
          height: rem(30px);
        }
      }
    }

    .settings {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: rem(10px);

      .settings__section {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.delete {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: rem(10px) rem(20px);
  border: 1px solid hsla(295, 100%, 42%, 1);
  transition: 0.5s ease-in-out;
  border-radius: rem(15px);
  font-size: rem(16px);

  @media (max-width: rem(500px)) {
    padding: rem(10px) rem(15px);
    gap: 5px;
    font-size: rem(14px);
  }

  &:hover {
    background-color: hsla(295, 100%, 42%, 1);
  }
}

.publish {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: rem(10px) rem(20px);
  border: 1px solid hsla(295, 100%, 42%, 1);
  border-radius: rem(15px);
  font-size: rem(16px);
  background-color: hsla(295, 100%, 42%, 1);

  @media (max-width: rem(500px)) {
    padding: rem(10px);
    gap: 5px;
    font-size: rem(14px);
  }
}

.icon {
  width: rem(30px);
  height: rem(30px);
  border-radius: 50%;
  fill: white;

  @media (max-width: rem(500px)) {
    width: rem(25px);
    height: rem(25px);
  }
}

.iconForCopy {
  width: rem(40px);
  height: rem(40px);
  border-radius: 50%;
  fill: white;

  @media (max-width: rem(500px)) {
    width: rem(25px);
    height: rem(25px);
  }
}