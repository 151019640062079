@import '~styles/mixins';
@import '~styles/variables';

.container {
  display: flex;
  width: 100%;
  white-space: nowrap;
  user-select: none;
  position: sticky;
  overflow-x: scroll;
  top: 200px;

  @media (max-width: 640px) {
    top: 0;
    margin: 0 rem(-18px);
    padding: 0 rem(18px);
    width: calc(100% + 1.125rem);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .content {
    .listSwitchers {
      background-color: #13111c;
      z-index: 99;
      display: flex;
      align-items: start;
      overflow-x: auto;
      justify-content: start;
      width: 100%;
      gap: rem(22px);

      @media (max-width: rem(1200px)) {
        width: 100%;
      }

      @media (max-width: 1150px) {
        padding: rem(39px) 0 rem(10px) 0;
      }
      @media (max-width: 640px) {
        padding: 0;
      }

      .itemSwitcher {
        transition: all 200ms ease-in-out;
        position: relative;
        padding: rem(12px) rem(20px);
        width: fit-content;
        border-radius: rem(100px);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(10px);
        background-color: #1e1c27;
        cursor: pointer;

        &:hover {
          background-color: #282631;
        }

        &__icon {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.link {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.itemSwitcher.active {
  background: linear-gradient(150deg, #9e5992, #6a19ab, #3054bb) !important;
}

.iconSetting {
  display: flex;
  align-items: center;
}

.itemSwitcher__title {
  font-size: rem(16px);
}
