@import '~styles/mixins';

.slider {
  max-width: rem(680px);
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  @media (max-width: rem(1150px)) {
    align-self: center;
    padding: rem(30px) rem(20px);
  }

  .control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .arrow {
      fill: hsla(240, 6%, 83%, 0.5);
    }
  }

  .sliders {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: rem(20px);

    @media (max-width: rem(500px)) {
      gap: rem(10px);
    }

    .slideWrapper {
      width: 100%;
      // height: 100%;
      position: relative;
      border-radius: rem(25px);
      overflow: hidden;

      .image {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .loaderWrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: rem(40px);
        height: rem(40px);
      }

      .blocked {
        z-index: 2;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(11px);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(rem(15px));
      }
    }
  }
}

.imageInput {
  aspect-ratio: 1;
  border-radius: rem(25px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: rem(17px);
  line-height: rem(21px);

  span,
  svg {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: var(--background-color);
    left: rem(1px);
    top: rem(1px);
    width: calc(100% - rem(2px));
    height: calc(100% - rem(2px));
    border-radius: rem(25px);
    z-index: 1;
  }
}