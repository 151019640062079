@import '~styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: none;
  pointer-events: none;
  transition: opacity 0.3s ease;
  @media (max-width: 640px) {
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    border: none;
    height: 100%;
  }

  &Open {
    display: block;
    pointer-events: auto;

    .modalBackout {
      opacity: 1;
    }
  }

  overflow: scroll;
}

.modalBackout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(19, 17, 28, 0.5);
  backdrop-filter: blur(rem(10px));
  transition: .3s;
  opacity: 0;
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: rem(22px);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: rem(513px);
  border-radius: 24px;
  border: 2px solid #1F1D28;
  background: #13111C;

  @media (max-width: 640px) {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    max-width: 100%;
    border: none;
    height: 100%;
  }

  .modalHeader {
    padding: 0 14px;
    display: flex;
    justify-content: space-between;
    height: rem(40px);
    align-items: center;
    position: relative;
  }
}

.modal {
  width: 100%;
  position: relative;
}

.content {
  border-radius: 24px;
  position: relative;
  width: 100%;
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media(max-width: 640px) {
    padding: 40px 14px;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(10px);
}

.header {
  margin-bottom: rem(32px);
}

.heading {
  display: flex;
  flex-direction: row;
  gap: rem(12px);

  .button {
    display: flex;
    height: 40px;
    padding: 0 12px;
    color: #FFF;
    text-align: center;
    font-size: rem(18px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 200px;
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.10);
    transition: background 50ms linear;

  }

  .buttonUnActive {
    background: #1E1C27;

    &:hover {
      background: #282631;
    }
  }

  .buttonActive {
    background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
  }
}


.mobileHeader{
  padding:  0 rem(16px);
}

  .title{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
