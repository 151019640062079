@import '~styles/mixins';
@import '~styles/variables';
.container {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 0 rem(10px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 200px;
  background: #1e1c27;
  transition: background 200ms linear;
  @media (max-width: 450px) {
    width: 35px;
    height: 35px;
  }

  &:hover {
    background: #282631;
  }

  @media (max-width: 640px) {
    background: rgba(30, 28, 39, 0.6);
  }

  .content {
    position: relative;
    height: rem(50px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .soundButton {
      display: flex;

      width: rem(50px);
      height: rem(50px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      border-radius: 200px 0px 0px 200px;
      .iconContainer {
        .iconSetting {
          display: flex;
          width: rem(30px);
          height: rem(30px);
          justify-content: center;
          align-items: center;
          gap: 2px;
          flex-shrink: 0;

          @media (max-width: 450px) {
            width: rem(24px);
            height: rem(24px);
          }
        }
      }
    }
  }
}
