@import '~styles/mixins';

.about {
  display: flex;
  flex-direction: column;
  gap: rem(50px);
  &Item {
  }
}



.list {
  margin-top: rem(20px);
  list-style: none;
  border-radius: rem(15px);
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  &Title {
    color: rgba(255, 255, 255, 0.70);
    font-size: rem(18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &Item {
    align-self: stretch;
    gap: rem(17px);
    display: flex;
    justify-content: start;
    align-items: center;
    background: #191724;
    &:nth-child(1){
      border-radius:rem(14px) rem(14px) 0 0 ;
    }
    &:last-child{
      border-radius: 0 0 rem(14px) rem(14px);
    }
  }
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  gap: rem(15px);
  color: rgba(#ffffff, 0.75);
  transition: background 50ms linear;
  &:hover {
    background: #282631;
  }

  &Text {
    font-size: rem(15px);
    font-weight: 500;
    line-height: rem(24px);
  }
  &StartIcon {
    fill: currentColor;
  }
  &EndIcon {
    margin-left: auto;
    opacity: 0.3;
    fill: currentColor;
    transform: rotate(180deg);
  }
}


.copyright {
  text-align: center;
  color: rgba(#ffffff, 0.5);
  font-size: rem(15px);
  font-weight: 500;
  line-height: rem(18px);
}

.borderButton{
  border-radius:rem(14px) rem(14px) 0 0 ;
}

.borderBottom{
  border-radius: 0 0 rem(14px) rem(14px);
}
