@import '~styles/mixins';

.main {
  flex-grow: 1;
  padding-bottom: var(--bottom-padding);
}

.text {
  text-align: center;
}

.link {
  margin-top: rem(25px);
  margin-left: auto;
  margin-right: auto;
  max-width: rem(350px);
}
