@import '~styles/mixins';
.columnsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(20px);
  max-width: rem(1080px);
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.loaderWrapper {
  width: 100%;
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}
