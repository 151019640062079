@import '~styles/mixins';

.profilePage {
  width: 100%;
  padding-bottom: rem(60px);

  @media (max-width: rem(400px)) {
    padding-top: rem(2px);
  }

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    @media (max-width: rem(1155px)) {
      margin: 0;
    }

    .loaderWrapper {
      width: 100%;
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: rem(40px);

      .loader {
        width: rem(40px);
        height: rem(40px);
      }
    }

    .profile {
      padding: rem(28px) rem(16px) 0 rem(16px);
      display: flex;
      gap: rem(3px);
      width: 100%;
      max-width: rem(1200px);
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: rem(972px)) {
        flex-direction: column;
        gap: rem(40px);
      }

      @media (max-width: 500px) {
        align-items: center;
      }

      @media (max-width: rem(400px)) {
        gap: rem(20px);
      }

      .profileActions {
        display: flex;
        gap: rem(10px);

        @media (max-width: rem(400px)) {
          display: none;
        }
      }

      .follow {
        font-weight: 600;
        font-size: rem(15px);
        line-height: rem(18px);
        padding: rem(14px) rem(15px);
        background: linear-gradient(150deg, #9e5992, #6a19ab, #3054bb);
        border-radius: rem(25px);
        width: fit-content;
        transition: 0.3s;

        &.followed {
          box-shadow: rem(10px) rem(10px) rem(5px) rem(200px) rgba(0, 0, 0, 0.3)
            inset;
          -webkit-box-shadow: rem(10px) rem(10px) rem(5px) rem(200px)
            rgba(0, 0, 0, 0.3) inset;
          -moz-box-shadow: rem(10px) rem(10px) rem(5px) rem(200px)
            rgba(0, 0, 0, 0.3) inset;
        }

        &Mobile {
          display: none;

          @media (max-width: rem(400px)) {
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: rem(30px);
          }
        }
      }

      .information {
        display: flex;
        align-items: flex-start;
        gap: rem(22px);

        @media (max-width: rem(680px)) {
          gap: rem(15px);
        }

        @media (max-width: 500px) {
          flex-direction: column;
          align-items: center;
        }

        .avatarWrapper {
          min-width: rem(160px);
          max-width: rem(160px);
          aspect-ratio: 1;
          box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
          border-radius: rem(250px);
          padding: rem(8px);
          overflow: hidden;
          position: relative;

          @media (max-width: rem(680px)) {
            padding: rem(5px);
          }

          .avatar {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: rem(200px);
          }
        }

        .user {
          display: flex;
          flex-direction: column;
          gap: rem(16px);

          @media (max-width: rem(500px)) {
            gap: rem(30px);
          }

          .nicknameWrapper {
            display: flex;
            flex-direction: column;
            gap: rem(10px);

            @media (max-width: rem(500px)) {
              align-items: center;
            }

            .nickname {
              font-weight: 600;
              font-size: rem(20px);
              line-height: rem(24px);

              @media (max-width: rem(680px)) {
                font-size: rem(22px);
                line-height: rem(18px);
              }

              .verifiedName {
                display: flex;
                align-items: center;
                gap: 10px;
              }
            }

            .description {
              font-weight: 500;
              font-size: rem(16px);
              line-height: rem(18px);

              @media (max-width: 500px) {
                text-align: center;
              }
            }

            .settingsLink {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: rem(10px);
              fill: white;
              transition: 0.3s;

              @media (max-width: rem(680px)) {
                font-size: rem(12px);
                line-height: rem(15px);
                gap: rem(5px);
              }

              @media (max-width: 500px) {
                display: none;
              }

              .settingsIcon {
                @media (max-width: rem(680px)) {
                  width: rem(20px);
                  height: rem(20px);
                }
              }
            }
          }
        }
      }

      .rightPart {
        display: flex;
        flex-direction: column;
        gap: rem(30px);
        align-items: flex-end;

        @media (max-width: rem(972px)) {
          align-items: center;
        }

        &Actions {
          display: grid;
          grid-template-columns: 1fr max-content;
          grid-template-rows: auto;
          gap: rem(10px);
          width: 100%;

          @media (min-width: rem(400px)) {
            display: none;
          }

          .message {
            grid-column: span 2;
            width: 100%;
          }
        }
      }

      .statistics {
        display: flex;
        padding: rem(22px);
        align-items: center;
        gap: rem(26px);

        @media (max-width: rem(500px)) {
          gap: rem(35px);
        }

        .statistic {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: rem(10px);

          .title {
            font-weight: 500;
            font-size: rem(16px);
            line-height: rem(18px);
            color: #d1d1d6;
          }

          .value {
            color: #fff;
            text-align: center;
            font-size: rem(25px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media (max-width: rem(680px)) {
              font-size: rem(24px);
              line-height: rem(21px);
            }
          }
        }
      }
    }

    .tabsWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: rem(12px);
      row-gap: rem(22px);
      margin: rem(40px) 0 0 0;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .createConcepts {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(11px);
        margin-left: auto;

        @media (max-width: 840px) {
          padding: 0 14px;
          justify-content: flex-start;
          width: 100%;
          margin-left: 0;
        }

        .textarea {
          flex: 1;
          width: rem(240px);
          height: auto;
          max-height: rem(200px);
          padding: rem(17px) rem(22px);
          color: #b0b0b8;
          font-size: rem(18px);
          font-weight: 400;
          font-family: 'Montserrat';
          background-color: #1e1c27;
          border-radius: rem(26px);
          outline: none;
          border: none;
          resize: none;

          &::placeholder {
            color: #b0b0b8;
          }
        }

        .createText,
        .conceptNameEdit {
          padding: rem(16px) rem(22px);
          width: max-content;
          height: max-content;
          font-size: rem(17px);
          border-radius: rem(200px);
          border: 2px solid transparent;
          background:
            linear-gradient(#13111c, #13111c) padding-box,
            linear-gradient(
                116deg,
                #9e5992 3.98%,
                #6a19ab 58.61%,
                #3054bb 112.12%
              )
              border-box;
        }

        .conceptNameEdit {
          padding: rem(16px) rem(22px);

          span {
            font-size: rem(17px);
          }
        }
      }
    }

    .tabsMain {
      width: 100%;
      padding: 0 14px;
      height: fit-content;
      display: flex;
      flex-direction: row;
      gap: rem(12px);

      @media (max-width: rem(1200px)) {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      @media (max-width: rem(600px)) {
        margin: rem(20px) 0 0 0;
      }
    }

    .tabLink {
      display: flex;
      padding: 6px 20px;
      height: 50px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      font-weight: 600;
      border-radius: 100px;
      background: #1e1c27;
      white-space: nowrap;

      transition: all 200ms ease-in-out;

      &:hover {
        background-color: #282631;
      }

      @media (max-width: 600px) {
        font-size: rem(17px);
        height: 40px;
      }

      .linkFill {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    .activeTab {
      background: linear-gradient(150deg, #9e5992, #6a19ab, #3054bb) !important;
    }
    .grid {
      padding-top: rem(20px);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(20px);

      @media (max-width: rem(1140px)) {
        grid-template-columns: repeat(3, minmax(rem(80px), rem(305px)));
      }

      @media (max-width: rem(600px)) {
        padding-top: rem(20px);
        gap: rem(10px);
      }
    }
  }
}

.admin {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 200px;
  background: #1e1c27;
  transition: background 50ms linear;
  &:hover {
    background: #282631;
  }
}
.switch {
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: rem(280px);
  margin-left: rem(-200px);
  display: flex;

  @media (max-width: rem(1150px)) {
    display: none;
  }

  @media (max-width: rem(1550px)) {
    left: 0;
    margin-left: 0;
  }
}

.settingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: fit-content;
  padding: rem(14px) rem(15px);
  background-color: #1e1c27;
  border-radius: rem(200px);
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #282631;
  }

  .settingContent {
    gap: rem(11px);
    display: flex;
    align-items: center;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.columnsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(20px);
  max-width: rem(1080px);

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wrapper {
  margin: rem(20px) 0 0 0;
}

.wrapperSettings {
  display: flex;
  align-items: center;
  gap: rem(10px);
}

.adminContainer {
  position: relative;
}

.adminPanel {
  border-radius: rem(10px);
  width: fit-content;
  background: #282631;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(14px);
  z-index: 1;

  & > button {
    text-wrap: nowrap;
  }

  @media (max-width: 580px) {
    right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 8%;
    border-left: rem(12px) solid transparent;
    border-right: rem(12px) solid transparent;
    border-bottom: rem(12px) solid #282631;
    width: 0;
    height: 0;

    @media (max-width: 580px) {
      right: 8%;
      left: auto;
    }
  }

  .reportAction {
    display: flex;
    align-items: center;
    color: #ec4b5b;
    width: 100%;
    cursor: pointer;
    padding: 10px 14px;
    font-size: rem(14px);
    font-weight: 600;
    user-select: none;
    border-radius: rem(10px);
    transition: background 0.2s linear;

    &:hover {
      background: #1e1c27;
    }
  }
}

.gridWrapper {
  width: 100%;
}
