@import '~styles/mixins';

.container {
  position: fixed;
  bottom: rem(20px);
  right: rem(20px);
  z-index: 10;

  @media (max-width: rem(1150px)) {
    bottom: rem(150px);
  }
  @media (max-width: rem(769px)) {
    display: none;
    z-index: -1;
  }

  .active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(55px);
    height: rem(55px);
    border-radius: 200px;
    background: #1e1c27;
    @media (max-width: rem(769px)) {
      display: none;
    }
  }
}
