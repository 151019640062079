@import '~styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(30px);
  background: rgba(19, 17, 28, 0.4);
  z-index: 1001;

  &.isScroll {
    align-items: flex-start;
    overflow: scroll;

    .container {
      border-radius: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: rem(33px);
    max-width: rem(508px);
    width: 100%;
    padding: rem(44px);
    border-radius: rem(26px);
    border: rem(3px) solid #1f1d28;
    background: #13111c;

    @media (max-width: 450px) {
      padding: rem(32px) rem(16px);
    }

    .title {
      font-weight: 500;
      font-size: rem(17.5px);
    }

    .list {
      list-style: none;
      border-radius: rem(15px);
      display: flex;
      flex-direction: column;
      gap: rem(5px);
      width: 100%;

      :first-child {
        .label {
          border-radius: rem(14px) rem(14px) 0 0;
        }
      }

      :last-child {
        .label {
          border-radius: 0 0 rem(14px) rem(14px);
        }
      }
      &Item {
        align-self: stretch;
        gap: rem(17px);
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;

        .label {
          cursor: pointer;
          width: 100%;
          padding: rem(15px) rem(25px);
          display: flex;
          align-items: center;
          gap: rem(15px);
          color: rgba(#ffffff, 0.75);
          font-size: rem(15px);
          font-weight: 500;
          line-height: rem(24px);
          transition: background-color 0.3s ease;
          background: #191724;
          &:hover {
            background: #282631;
          }
        }

        .checkIcon {
          fill: currentColor;
          opacity: 0.3;
          margin-left: auto;
        }
      }
    }

    .button {
      width: fit-content;
    }
  }
}
