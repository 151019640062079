@import '~styles/mixins';

.searchContent {
  width: 100%;
  padding: rem(50px) 0;
  max-width: 980px;

  .container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: rem(22px);
    width: 100%;

    @media (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .nothingText {
    position: absolute;
    top: 0;
    left: 50%;
    color: #d1d1d6;
    font-size: rem(16px);
    font-weight: 400;
    transform: translateX(-50%);
  }
}

.loaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: rem(12px);

  width: 100%;
  height: rem(40px);
  margin-top: 60px;

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
  .loaderText {
    color: #d1d1d6;
    font-size: 14px;
    font-weight: 400;
  }
}

.loaderWrapperInfinity {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
