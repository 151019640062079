@import '~styles/mixins';

.albumPhotos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .albumActions {
    position: absolute;
    right: 0;
    bottom: 100%;
    z-index: 2;

    @media (max-width: 950px) {
      top: rem(20px);
      left: 0;
      padding: 0 14px;
    }

    .action {
      position: relative;
      display: flex;
      gap: rem(11px);

      .textarea {
        width: rem(240px);
        height: auto;
        max-height: rem(200px);
        padding: rem(17px) rem(22px);
        color: #b0b0b8;
        font-size: rem(18px);
        font-weight: 400;
        font-family: 'Montserrat';
        background-color: #1e1c27;
        border-radius: rem(26px);
        outline: none;
        border: none;
        resize: none;

        &::placeholder {
          color: #b0b0b8;
        }
      }

      .createText,
      .conceptNameEdit {
        padding: rem(16px) rem(22px);
        width: max-content;
        height: max-content;
        font-size: rem(17px);
        border-radius: rem(200px);
        border: 2px solid transparent;
        background:
          linear-gradient(#13111c, #13111c) padding-box,
          linear-gradient(
              116deg,
              #9e5992 3.98%,
              #6a19ab 58.61%,
              #3054bb 112.12%
            )
            border-box;
      }

      .conceptNameEdit {
        padding: rem(16px) rem(22px);

        span {
          font-size: rem(17px);
        }
      }
    }
  }

  .grid {
    padding-top: rem(20px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(20px);

    @media (max-width: rem(950px)) {
      grid-template-columns: repeat(3, 1fr);
      padding-inline: 14px;
      padding-top: rem(100px);
    }

    @media (max-width: rem(700px)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: rem(430px)) {
      grid-template-columns: 1fr;
    }

    @media (max-width: rem(600px)) {
      gap: rem(10px);
    }

    .addImage {
      aspect-ratio: 1;
      height: 100%;
      width: 100%;
      border: 2px solid #ffffff;
      border-style: dashed;
    }

    .wrapper {
      height: 0;
      width: 100%;
      border-radius: rem(20px);
      overflow: hidden;
      position: relative;
      animation-name: appearing;
      animation-duration: 2s;

      @media (max-width: rem(600px)) {
        border-radius: rem(15px);
      }

      @keyframes appearing {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
