.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  
}

.image {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
