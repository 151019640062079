@import '~styles/mixins';

.link {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: rem(20px);
  min-height: rem(300px);
  padding-top: rem(20px);

  @media (max-width: rem(950px)) {
    grid-template-columns: repeat(3, 1fr);
    padding-inline: 14px;
  }

  @media (max-width: rem(760px)) {
    padding-top: rem(100px);
  }
  @media (max-width: rem(700px)) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: rem(430px)) {
    grid-template-columns: 1fr;
  }

  @media (max-width: rem(600px)) {
    gap: rem(10px);
  }
}
