@import '~styles/mixins';

.container {
  height: 100%;
  width: 100%;
  position: relative;

  .content {
    height: 100%;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .scrollContainer {
      position: sticky;
      bottom: 0;
      padding: 0 rem(22px) rem(23px) rem(22px) ;
    }

  }
}
