@import '~styles/mixins';

.plugin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s linear;
  z-index: 99;
  @media (max-width: 640px) {
    gap: rem(2px);
    padding: 0 rem(13px);
    justify-content: flex-start;
    background-color: #14121d;
  }
}
