@import '~styles/mixins';

.containerStylesUnActive {
  transition: background linear 200ms;
  &:hover {
    background: #1e1c27;
  }
}

.containerStyles {
  margin-top: rem(22px);
  display: flex;
  width: 100%;
  padding: rem(22px);
  flex-direction: column;
  align-items: flex-start;
  gap: rem(22px);
  border-radius: rem(26px);
  border: 2px solid #1f1d28;
  box-shadow: 0 0 rem(22px) 0 rgba(0, 0, 0, 0.15);

  .wrapperStyles {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .rightPart {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: rem(12px);

      .displayStyle {
        display: flex;
        padding: rem(8px) rem(6px);
        justify-content: center;
        align-items: center;
        font-weight: 500;
        gap: rem(12px);
        border-radius: 200px;
        background: linear-gradient(
          116deg,
          #9e5992 3.98%,
          #6a19ab 58.61%,
          #3054bb 112.12%
        );
      }
      .arrowIcon {
        transition: transform 0.2s ease-in-out;
        transform: rotate(0deg);
      }

      .arrowIconOpen {
        transform: rotate(180deg);
      }
    }
  }

  .styles {
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3, 33%);

    &Title {
      font-weight: 600;
      font-size: 16px;
      line-height: rem(24px);
      white-space: nowrap;
      z-index: 2;
    }
    @media (max-width: rem(645px)) {
      column-gap: rem(10px);
      row-gap: rem(10px);
      grid-template-rows: unset;
    }
    &Variant {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: rem(8px);
      padding: rem(12px);
      position: relative;
      transition: 0.3s;
      z-index: 1;
      border-radius: rem(16px);

      &:hover {
        background: #1e1c27;
      }

      &.active {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: rem(16px);
          padding: rem(2px);
          background: linear-gradient(160deg, #9e5992, #6a19ab, #3054bb);
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: destination-out;
          mask-composite: exclude;
        }
      }

      &::after {
        z-index: 1;
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: rem(25px);
      }

      .picture {
        z-index: 2;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: rem(25px);
      }
      .text {
        width: 100%;
        display: flex;
        white-space: nowrap;
        justify-content: center;
        font-weight: 500;
        font-size: rem(15px);
        line-height: normal;
      }
    }
  }
}

.settingsEnter {
  opacity: 0;
  transform: scale(0);
}

.settingsEnterActive {
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 100ms ease-in-out,
    transform 200ms ease-in-out;
}

.settingsExit {
  opacity: 1;
}

.settingsExitActive {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.moreStyles {
  padding: 10px;
  align-self: end;
}
