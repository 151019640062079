@import '~styles/mixins';

.imageToComment {
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    aspect-ratio: 1;
}

.setInitial {
    text-align: center;
    max-width: rem(320px);
    width: 100%;
    height: 100%;
    padding: rem(15px) 0;
    border-radius: rem(30px);
    background-color: rgb(116, 100, 122);
    margin: rem(20px) auto 0 auto;

    @media (max-width: 425px) {
        font-size:rem(10px);
        padding:rem(10px);
    }
}

.processing {
    padding: 5rem;
    padding-bottom: 10rem;
}

.finishStage {
    width: 100%;
    height: 100%;

    .finish {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 500px;
        align-items: start;
        justify-content: center;
        gap: rem(40px);
        width: 100%;
        height: 100%;

        @media (max-width: rem(1070px)) {
            gap: rem(20px);
            grid-template-columns: 1fr;
            grid-template-rows: 500px 200px;
        }
    }

    .controlPanel {
        display: flex;
        flex-direction: column;
        gap: rem(20px);

        @media (max-width: rem(1070px)) {
            align-items: center;
            justify-content: center;
        }
    }
}

.settings {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(10px);

    @media (max-width: 425px) {
        flex-direction: column;
    }

    &.extra {
        @media (max-width: rem(1070px)) {
            padding-bottom: rem(25px);
        }
    }
}

.fixed {
    pointer-events: none;
    z-index: 10;
    position: fixed;
    left: 0;
    bottom: 35px;
    width: 100%;
    height: rem(273px);

    .button {
        pointer-events: all;
        bottom: rem(70px);
        left: 50%;
        transform: translateX(-50%);
        position: absolute;

        min-width: rem(350px);
        border-radius: rem(30px);

        @media (max-width: rem(575px)) {
            min-width: rem(250px);
            // width: 100%;
            margin: 0 auto;
        }
    }
}