@import '~styles/mixins';
@import '~styles/variables';

.post {
  padding: rem(15px);
  display: flex;
  background: #191724;
  border-radius: rem(24px);
  flex-direction: column;
  width: 100%;
  height: 100%;
  animation-name: appearing;
  animation-duration: 2s;

  @media (max-width: rem(840px)) {
    padding: rem(15px) rem(10px);
  }

  @keyframes appearing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

    .postImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: rem(26px);

      @media (max-width: rem(768px)) {
        border-radius: rem(15px);
      }
    }

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .linkForVideo {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90%;

    @media (max-width: rem(600px)) {
      height: 85%;
    }

    @media (max-width: rem(370px)) {
      height: 80%;
    }
  }

  &Top {
    display: flex;
    padding: 0 rem(11px) 0 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: rem(13px);

    @media (max-width: rem(950px)) {
      margin-top: rem(15px);
    }

    .replyContainer{
      display: flex;
      width: 100%;
      margin-left: rem(22px);
      justify-content: start;
      align-items: center;
      @media(max-width: 350px){
        margin-left: rem(6px);
      }
      .iconReply{
        width: 24px;
        height: 24px;

        @media(max-width: 360px){
          width: 20px;
          height: 20px;
        }
      }
      .imgWrapper{
        margin-left: rem(22px);

        @media(max-width: 350px){
          margin-left: rem(6px);
        }
        .replyCommentImg{
          width: rem(45px);
          height: rem(45px);
          border-radius: rem(10px);
        }
      }
      .replyText {
        margin-left: rem(12px);
        overflow: hidden;
        color:  #D1D1D6;
        text-overflow: ellipsis;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        align-self: stretch;
        display: flex;
        align-items: center;

        @media(max-width: 350px){
          margin-left: rem(6px);
        }
      }
    }

  }

  &User {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: rem(20px);
    position: relative;

    &:hover {
      color: hsla(248, 100%, 78%, 1);
    }

    @media (max-width: rem(400px)) {
      gap: rem(10px);
    }

    .avatarWrapper {
      overflow: hidden;
      width: rem(45px);
      height: rem(45px);
      border-radius: rem(100px);
      user-select: none;
      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      }

      @media (max-width: rem(400px)) {
        width: rem(40px);
        height: rem(40px);
        border-radius: rem(15px);
      }
    }

    .nicknameWrapper {
      display: flex;
      flex-direction: column;
      gap: rem(5px);
      padding-top: rem(4px);

      .nickname {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(24px);
        transition: 0.3s;

        @media (max-width: rem(400px)) {
          font-size: rem(15px);
          line-height: rem(18px);
        }
      }

      .date {
        font-weight: 400;
        font-size: rem(11px);
        line-height: rem(6px);
        color: hsla(240, 6%, 83%, 1);

        @media (max-width: rem(400px)) {
          font-size: rem(10px);
          line-height: rem(12px);
        }
      }
    }
  }

  &FeedBack {
    padding: rem(13px) 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .postToolButton {
      white-space: nowrap;
      transition: background-color 50ms linear;
      display: flex;
      cursor:pointer;
      height: rem(42px);
      padding: 0 rem(12px);
      justify-content: center;
      align-items: center;
      gap: rem(8px);
      border-radius: rem(200px);

      &:hover:not([disabled]){
        background-color: #282631;
      }

      &[disabled]{
        pointer-events: none;
      }

      &Icon {
        fill: white;
        position: relative;

        .favoredIcon {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s;

          &.favored {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &Count {
        font-weight: 500;
        font-size: rem(15px);
        line-height: rem(18px);

        @media (max-width: rem(400px)) {
          font-size: rem(12px);
          line-height: rem(15px);
        }
      }
    }

    .postViews {
      color:  #948AA3;
      font-size: rem(13px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(6px);
      @media (max-width: rem(400px)) {
        font-size: rem(10px);
        line-height: rem(12px);
      }

    }
    .toolsFeedback{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(10px);
      width: 100%;

      @media(max-width: 600px){
        flex-direction: column;
      }

      .leftContainerPart{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @media (max-width: 600px) {
          justify-content: space-between;
        }

        .feedBackWrapper{
          display: flex;
          width: 100%;
          gap: rem(10px);
          align-items: center;
          justify-content: start;
        }
        .more {
          border-radius: 200px;
          white-space: nowrap;
          background: rgba(235, 235, 245, 0.05);
          display: flex;
          height: 40px;
          padding: 0px 10px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          display: none;
          transition: background-color 50ms linear;

          @media (max-width: 600px) {
            display: flex;
            font-size: rem(13px);
          }

          &:hover {

            background-color: #282631;
          }
        }
        .postComments{
          display: flex;
          flex-direction: row;
        }

      }
      .rightContainerPart{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: rem(22px);

        .postReply{
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: end;

          @media(max-width: 600px){
            justify-content: center;
            width: 100%;
          }
        }
        .more{
          border-radius: 200px;
          white-space: nowrap;
          background: rgba(235, 235, 245, 0.05);
          display: flex;
          height: 40px;
          padding: 0px 10px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          transition: background-color 50ms linear;

          @media (max-width: 600px) {
            display: none;
          }

          &:hover{

            background-color: #282631;
          }
        }
      }
    }
  }

  &Bottom {
    padding: rem(13px) 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .containerShowOverflowContent{
      display: flex;
      justify-content: start;
      align-items: start;
      color:  #D1D1D6;
      padding-top: rem(6px);
      font-size: rem(15px);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }


    .textExpanded {
      overflow: visible;
      -webkit-line-clamp: none;
      color:  #D1D1D6;
      text-overflow: ellipsis;
      font-size: rem(15px);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      @media (max-width: rem(400px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }

    .text {

      overflow: hidden;
      color:  #D1D1D6;
      font-size: rem(15px);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      &Style {
        position: relative;
        padding: rem(6px)  rem(12px);
        border-radius: 30px;
        background: #282631;
        text-align: center;

        &::before {
          content:"";
          position:absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
          border-radius:rem(50px);
          padding:rem(1px);
          background: linear-gradient(160deg , #9E5992 , #6A19AB , #3054BB );
          -webkit-mask:
                  linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
          -webkit-mask-composite: destination-out;
          mask-composite: exclude;
        }
      }

      @media (max-width: rem(400px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }

    .postCopy {
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      fill: white;

      @media (max-width: rem(400px)) {
        width: rem(30px);
        height: rem(30px);
      }
      &:hover {
        background: #282631;
      }
    }
  }
  .admin {
    display: flex;
    flex-direction: column;
    gap: rem(10px);

    .delete {
      padding: rem(10px);
      border: 1px solid hsla(295, 100%, 42%, 1);
      transition: 0.2s;
      border-radius: rem(15px);
      font-size: rem(16px);

      &:hover {
        background-color: hsla(295, 100%, 42%, 1);
      }
    }
  }

  .leftPart, .rightPart {
    padding: rem(13px) rem(13px) 0 rem(13px);
    position: relative;
    display: flex;
    align-items:center;
    justify-content: center;
    gap: rem(30px)
  }

  .leftArrow,
  .rightArrow {
    width: rem(50px);
    height: rem(50px);

    .icon {
      width: 100%;
      height: 100%;
      fill: rgb(255, 255, 255);
    }
  }

  .related {
    border-radius: rem(20px);
    padding: rem(10px);
    border: 1px solid hsla(295, 100%, 42%, 1);

    @media (max-width: rem(450px)) {
      font-size: rem(12px);
      line-height: rem(15px);
    }
  }


  .text {
    font-weight: 500;
    font-size: rem(15px);
    line-height: 160%;
    max-width: 90%;

    &Style {
      position: relative;
      padding: rem(6px)  rem(12px);
      border-radius: 30px;
      background: #282631;
      text-align: center;

      @media(max-width:600px){
        font-size: rem(12px);
      }


      &::before {
        content:"";
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border-radius:rem(50px);
        padding:rem(1px);
        background: linear-gradient(160deg , #9E5992 , #6A19AB , #3054BB );
        -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }
    }

    @media (max-width: rem(400px)) {
      font-size: rem(12px);
      line-height: rem(15px);
    }
  }

  .postTags{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: rem(6px);
  }


  .imgWrapper{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .textStyleContainer{
    margin: rem(10px) 0;
  }

  .postImgContainer {

    position: relative;
  }

  .textStyleSub{
    position: relative;
    padding: rem(6px)  rem(12px);
    border-radius: 30px;
    background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
    text-align: center;

    @media(max-width:600px){
      font-size: rem(12px);
    }

  }


  .fullScreenContainer {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s;
    z-index: 9;
    display: flex;
    align-items: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(50px);
    margin: rem(15px);
    border-radius: 20px;
    padding: rem(8px);
    top: 0;
    right: 0;
    transition: background 50ms linear;
    @media(max-width: 650px){
      visibility: visible;
      opacity: 1;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.50);
    }

  }

  .imgWrapper {
    position: relative;
  }

  .imgWrapper:hover .fullScreenContainer {
    opacity: 1;
    visibility: visible;
  }


  .iconSetting{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonTextContainer{
    padding: rem(2px);

    .buttonText {
      padding: rem(5px);
      text-decoration: underline;
      transition: color 200ms linear;
      &:hover {
        color: #fff;
      }
    }
  }



  .comments{
    margin-top: rem(20px);
    flex-direction: column;
  }

  .recursivePost {
    display: flex;
    background: #191724;
    flex-direction: column;
    width: 100%;
    height: 100%;
    animation-name: appearing;
    animation-duration: 2s;
    padding: 0 ;

    @keyframes appearing {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

  }

  #recursiveLine {
    border-top: 1px solid #282631;
    width: 100%;
    margin-bottom: rem(18px);
  }

}


.commentsWrapper {
  padding-left: rem(40px);
}