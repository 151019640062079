@import '~styles/mixins';

.gridWrapper {
  position: relative;
  width: 100%;
}

.albumActions {
  position: absolute;
  right: 0;
  bottom: 100%;
  z-index: 2;

  @media (max-width: 760px) {
    top: rem(20px);
    left: 0;
  }

  .action {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(11px);
    margin-left: auto;

    @media (max-width: 840px) {
      padding: 0 14px;
      justify-content: flex-start;
      width: 100%;
      margin-left: 0;
    }

    .textarea {
      flex: 1;
      width: rem(240px);
      height: auto;
      max-height: rem(200px);
      padding: rem(17px) rem(22px);
      color: #b0b0b8;
      font-size: rem(18px);
      font-weight: 400;
      font-family: 'Montserrat';
      background-color: #1e1c27;
      border-radius: rem(26px);
      outline: none;
      border: none;
      resize: none;

      &::placeholder {
        color: #b0b0b8;
      }
    }

    .createText,
    .conceptNameEdit {
      padding: rem(16px) rem(22px);
      width: max-content;
      height: max-content;
      font-size: rem(17px);
      border-radius: rem(200px);
      border: 2px solid transparent;
      background:
        linear-gradient(#13111c, #13111c) padding-box,
        linear-gradient(116deg, #9e5992 3.98%, #6a19ab 58.61%, #3054bb 112.12%)
          border-box;
    }

    .conceptNameEdit {
      padding: rem(16px) rem(22px);

      span {
        font-size: rem(17px);
      }
    }
  }
}

.loaderWrapper {
  width: 100%;
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}
