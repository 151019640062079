@import '~styles/mixins';

.generationFinishPage {
  //flex-grow: 1;
  // padding-bottom: var(--bottom-padding);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container{
  width: 100%;
  height: 100%;
}