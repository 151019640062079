@import '~styles/mixins';

.search {
  padding: rem(42px) 0;

  @media (max-width: 640px) {
    padding-inline: rem(15px);
  }

  .searchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
