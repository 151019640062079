@import '~styles/mixins';

.sortContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: rem(22px);
  width: 70%;
  margin-bottom: rem(22px);

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 760px) {
    flex-direction: row;
  }

  .sortTitle {
    font-size: rem(22px);
    color: #fff;

    @media(max-width: 768px) {
      font-size: rem(16px);
    }
  }

  .selectContainer {
    display: flex;
    gap: rem(11px);
  }

  .sortItem {
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }

    span {
      display: flex;
      align-items: center;
      gap: rem(6px);
      padding: rem(13px) rem(22px);
      font-size: rem(18px);
      color: #fff;
      background: #1e1c27;
      border-radius: 100px;
      transition: all 1s ease;

      @media(max-width: 768px){
        font-size: rem(16px);
        padding: rem(10px) rem(16px);
      }
    }

    input:checked + span {
      background: linear-gradient(
        116deg,
        #9e5992 3.98%,
        #6a19ab 58.61%,
        #3054bb 112.12%
      );
    }
  }
}
