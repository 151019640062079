@import '~styles/mixins';

.post {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: rem(650px);
  animation-name: appearing;
  animation-duration: 2s;

  @keyframes appearing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .postImgWrapperTrending, .postImgWrapper {
    position: relative;
    overflow: hidden;
    border-radius: rem(25px);
    // aspect-ratio: 1;

    @media (max-width: rem(768px)) {
      border-radius: rem(15px);
    }

    @media (max-width: rem(390px)) {
      border-radius: rem(15px);
    }

    @media (max-width: rem(320px)) {
      border-radius: rem(15px);
    }

    .postImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  
  .postImgWrapperTrending {
    aspect-ratio: 1;
  }

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .linkForVideo {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90%;

    @media (max-width: rem(600px)) {
      height: 85%;
    }

    @media (max-width: rem(370px)) {
      height: 80%;
    }
  }

  &Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: rem(20px);
    padding-bottom: rem(13px);

    @media (max-width: rem(950px)) {
      margin-top: rem(15px);
    }
  }

  &User {
    display: flex;
    flex-direction: row;
    gap: rem(20px);
    position: relative;

    &:hover {
      color: hsla(248, 100%, 78%, 1);
    }

    @media (max-width: rem(400px)) {
      gap: rem(10px);
    }

    .avatarWrapper {
      overflow: hidden;
      width: rem(65px);
      height: rem(65px);
      border-radius: rem(20px);

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      }

      @media (max-width: rem(400px)) {
        width: rem(40px);
        height: rem(40px);
        border-radius: rem(15px);
      }
    }

    .nicknameWrapper {
      display: flex;
      flex-direction: column;
      gap: rem(5px);
      padding-top: rem(4px);

      .nickname {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        font-size: rem(20px);
        line-height: rem(24px);
        transition: 0.3s;

        @media (max-width: rem(400px)) {
          font-size: rem(15px);
          line-height: rem(18px);
        }
      }

      .date {
        font-weight: 400;
        font-size: rem(15px);
        line-height: rem(18px);
        color: hsla(240, 6%, 83%, 1);

        @media (max-width: rem(400px)) {
          font-size: rem(10px);
          line-height: rem(12px);
        }
      }
    }
  }

  &FeedBack {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .postLikes {
      display: flex;
      flex-direction: row;
      gap: rem(10px);
      align-items: center;
      cursor: pointer;

      &Icon {
        fill: white;
        position: relative;

        .favoredIcon {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s;

          &.favored {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &Count {
        font-weight: 500;
        font-size: rem(15px);
        line-height: rem(18px);

        @media (max-width: rem(400px)) {
          font-size: rem(12px);
          line-height: rem(15px);
        }
      }
    }

    .postViews {
      font-weight: 400;
      font-size: rem(15px);
      line-height: rem(18px);
      color: hsla(240, 6%, 83%, 1);

      @media (max-width: rem(400px)) {
        font-size: rem(10px);
        line-height: rem(12px);
      }
    }
  }

  &Bottom {
    padding: rem(13px) rem(13px) 0 rem(13px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .text {
      font-weight: 500;
      font-size: rem(15px);
      line-height: 150%;
      max-width: 90%;

      &Style {
        position: relative;
        border: 1px solid hsla(295, 100%, 42%, 1);
        border-radius: rem(20px);
        padding: rem(2px);
      }

      @media (max-width: rem(400px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }

    .postCopy {
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      fill: white;

      @media (max-width: rem(400px)) {
        width: rem(30px);
        height: rem(30px);
      }
    }
  }
}

.admin {
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  .delete {
    padding: rem(10px);
    border: 1px solid hsla(295, 100%, 42%, 1);
    transition: 0.2s;
    border-radius: rem(15px);
    font-size: rem(16px);
    
    @media (max-width: rem(450px)) {
      font-size: rem(12px);
      line-height: rem(15px);
    }

    &:hover {
      background-color: hsla(295, 100%, 42%, 1);
    }
  }
}

.leftPart, .rightPart {
  padding: rem(13px) 0;
  display: flex;
  align-items:center;
  justify-content: center;
  gap: rem(30px)
}

.leftArrow,
.rightArrow {
  width: rem(50px);
  height: rem(50px);
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    fill: rgb(255, 255, 255);
  }
}

.text {
  font-weight: 500;
  font-size: rem(15px);
  line-height: 160%;
  max-width: 90%;

  &Style {
    position: relative;
    border: 1px solid hsla(295, 100%, 42%, 1);
    border-radius: rem(20px);
    padding: rem(3px)  rem(6px);
    margin-left: rem(6px);
  }

  @media (max-width: rem(400px)) {
    font-size: rem(12px);
    line-height: rem(15px);
  }
}

.relatedDalle3{
  border-radius: rem(20px);
  padding: rem(10px);
  user-select: none;
  border: 1px solid hsla(295, 100%, 42%, 1);

  @media (max-width: rem(450px)) {
    font-size: rem(12px);
    line-height: rem(15px);
  }
}


.relatedContainer{
  display: flex;
  gap: rem(12px);
}


.rightPart{
  display:flex;
  gap: rem(10px);
}

.tagContainer{
  display: flex;
  flex-direction: row;
  padding: rem(4px) rem(13px) 0 rem(13px);
  gap: rem(5px);
  align-items: center;
  .textStyle {
    border-radius: rem(20px);
    padding: rem(3px)  rem(6px);
    border: 1px solid hsla(295, 100%, 42%, 1);
  
    @media (max-width: rem(450px)) {
      font-size: rem(12px);
      line-height: rem(15px);
    }
  }
}

    .relatedDalle3{
      border-radius: rem(20px);
      padding: rem(3px)  rem(6px);
      border: 1px solid 
      #734be3;
      background-color: 
      #734be3;
    
      @media (max-width: rem(450px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }

    .relatedRecommended{
      border-radius: rem(20px);
      padding: rem(3px)  rem(6px);
      border: 1px solid hsla(295, 100%, 42%, 1);
      background-color: hsla(295, 100%, 42%, 1);
      @media (max-width: rem(450px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }