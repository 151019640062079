@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  border-right: 2px solid #1E1C26;
  @media(max-width: 950px) {
    max-width: 100%;
    border: 0;
  }

  .content {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
  }
}
