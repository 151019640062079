@import '~styles/mixins';

.assetContainer {
  display: flex;
  position: relative;
  height: 100%;
  border-radius: rem(26px);
  padding: rem(16px);
  gap: rem(16px);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: #191724;
  .assetImageWrapper {
    display: flex;
    width: 100%;
    aspect-ratio: 1/1;
    align-items: center;
    justify-content: center;
    &:has(video) {
      aspect-ratio: initial;
      min-height: rem(240px);
    }
  }
  .assetImage {
    width: 100%;
    border-radius: rem(26px);
    object-fit: contain;
  }

  .assetActions {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    gap: rem(12px);
    user-select: none;
    .shareAction {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: linear-gradient(
        116deg,
        #9e5992 3.98%,
        #6a19ab 58.61%,
        #3054bb 112.12%
      );
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      display: flex;
      height: rem(42px);
      padding: 0 rem(16px);
      justify-content: center;
      align-items: center;
      gap: rem(8px);
      &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }
    .variationsAction {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: rgba(235, 235, 245, 0.05);
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      display: flex;
      height: rem(42px);
      padding: 0 rem(16px);
      justify-content: center;
      align-items: center;
      gap: rem(8px);
      &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  .favoriteWrapper {
    position: absolute;
    top: rem(10px);
    left: rem(10px);
    height: rem(40px);
    width: rem(40px);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .fullScreenContainer {
    transition:
      opacity 0.5s ease,
      visibility 0.5s;
    z-index: 9;
    display: flex;
    align-items: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    margin: rem(30px);
    border-radius: 20px;
    padding: rem(8px);
    top: 0;
    right: 0;
    transition: background 50ms linear;
    @media (max-width: 650px) {
      visibility: visible;
      opacity: 1;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .iconSetting {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
