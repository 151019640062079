@import '~styles/mixins';

.feedPage {
  padding-bottom: rem(60px);
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loaderWrapper {
      width: 100%;
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;

      .loader {
        width: rem(40px);
        height: rem(40px);
      }
    }

    .feed {
      display: flex;
      flex-direction: column;
      gap: rem(60px);
    }
  }
}
.columnsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(20px);

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wrapper {
  height: 100%;
  margin: rem(20px) 0 0 0;
}
