@import '~styles/mixins';

.fileUpload {
  display: flex;
  align-items: center;
  position: relative;

  &Scroll {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  &Item {
    padding: 4px;
    border-radius: 24px;
    background: #1e1c27;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    min-width: 172px;
    min-height: 172px;
    width: 100%;
    cursor: pointer;

    $root: &;

    &:hover {
      #{$root}Wrapper {
        border-color: #fff;
      }
    }

    &Wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 24px;
      border: 1px dashed #53525d;
      padding: 10px;
      aspect-ratio: 1/1;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      transition: 0.3s;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .itemText {
  }

  .uploadButton {
    pointer-events: none;
  }

  .item {
    $root: &;
    display: flex;
    width: 172px;
    height: 172px;
    aspect-ratio: 1/1;
    min-width: 172px;
    min-height: 172px;
    overflow: hidden;
    border-radius: 24px;
    background: #1e1c27;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 4px;

    &Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }

    &Remove {
      position: absolute;
      right: 8%;
      top: 8%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: rem(5px);
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
      fill: #fff;
      transition: 0.3s;
      backdrop-filter: blur(50px);
      opacity: 0;
      visibility: visible;

      @media (hover: hover) {
        &:hover {
          background: rgba(0, 0, 0, 0.7);
        }
      }
      @media (hover: none) {
        transition: 0.5s;
        &:active {
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        .itemRemove {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    @media (hover: none) {
      .itemRemove {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
