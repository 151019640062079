@import 'mixins';
@import 'variables';

:root {
  touch-action: var(--touch-action, pan-y);
  --background-color: #13111c;
  --bottom-height: rem(
    150px
  ); // Максимальная высота блока на всех 4-х экранах (для того, чтобы не "прыгал" блок с картинкой)
  --bottom-padding: rem(50px);
  --swiper-navigation-color: #4628ff;
  --swiper-theme-color: #4628ff;
  @media (max-width: rem(767px)) {
    --bottom-height: rem(160px);
    --bottom-padding: rem(25px);
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: $html-font-size;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
}

html,
body {
  ::-webkit-scrollbar {
    width: rem(8px);
    height: rem(6px);
  }

  ::-webkit-scrollbar-track {
    background: rgba(19, 17, 28, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(
      to top,
      rgba(196, 0, 214, 0.2),
      rgba(70, 40, 255, 0.2)
    );
    border-radius: 200px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  background-color: transparent;
  border: none;
}

.input-range__track.input-range__track--background {
  height: rem(3px) !important;
  background-color: #191724;
}

.input-range__track.input-range__track--active {
  height: rem(3px);
  background-color: #681bac !important;
}

.input-range__slider {
  background-color: #681bac !important;
  z-index: 1;
}

.lightbox-open {
  z-index: 100;
}
