@import '~styles/mixins';

.wrapper {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(17px);
  height: 100%;
  width: 100%;
  padding: rem(15px);
  background: #191724;
  border-radius: rem(24px);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  // overflow: hidden;
  animation-name: appearing;
  animation-duration: 2s;

  &:hover {
    background: #24222f;
  }

  .fullScreenContainer {
    position: absolute;
    top: 0;
    right: 0;
    margin: rem(10px);
    transition:
      opacity 0.5s ease,
      visibility 0.5s;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    border-radius: 20px;
    padding: rem(8px);
    transition: background 50ms linear;

    @media (max-width: 650px) {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }

    .iconSetting {
      display: flex;
    }
  }

  .conceptFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    height: 100%;
    width: 100%;

    .checkbox {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: flex;
      width: rem(27px);
      height: rem(27px);
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 6px;
      border: rem(3px) solid #383744;
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      &:checked {
        border: rem(3px) solid #fff;
        background-image: url('../icons/CheckboxTickIcon.svg');
        box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      }
    }

    .conceptTitle {
      font-size: rem(18px);
      font-weight: 500;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15ch;
      @media (max-width: 600px) {
        font-size: rem(15px);
      }
    }

    @media (max-width: 350px) {
      font-size: rem(15px);
    }

    .actions {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: rgba(235, 235, 245, 0.05);
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      display: flex;
      height: rem(42px);
      padding: 0 rem(12px);
      justify-content: center;
      align-items: center;
      transition: background 50ms linear;

      &:hover {
        background: #282631;
      }
    }
  }

  @media (max-width: rem(600px)) {
    border-radius: rem(15px);
  }

  @keyframes appearing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .image {
    width: 100%;
    border-radius: rem(25px);
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .noImage {
    width: 100%;
    border-radius: rem(25px);
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
