.main {
  overflow: hidden;
  width: 100vw;
  height: var(--height, 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: absolute;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &Wrapper{
    max-width: 40px;
    max-height: 40px;
  }
  &Text {
    color: #ffffff;
    text-align: center;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.error {
  color: #ffffff;
}
