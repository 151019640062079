@import '~styles/mixins';

.slider {
    max-width: rem(680px);
    display: flex;
    flex-direction: column;
    gap: rem(10px);
  
    .control {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .arrow {
        fill: hsla(240, 6%, 83%, 0.5);
      }
    }
  
    .sliders {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: rem(19px);
  
      .slideWrapper {
        aspect-ratio: 1;
        width: 100%;
        // height: 100%;
        position: relative;
        border-radius: rem(25px);
        overflow: hidden;
  
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .loaderWrapper {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: rem(40px);
          height: rem(40px);
        }
  
        .blocked {
          z-index: 2;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: rem(11px);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          backdrop-filter: blur(rem(15px));
        }
      }
    }
  }