@import '~styles/mixins';
@import '~styles/variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;

  &.visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 0s;
  }

  .settingsWrapper {
    border-radius: rem(10px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 140%;
    left: 50%;
    transform: translateX(-82%);
    z-index: 999;
    padding: 40px;

    .settingsWindow {
      border-radius: rem(10px);
      width: rem(150px);
      height: fit-content;
      background-color: #282631;
      position: relative;
      z-index: 999;

      .actions {
        font-size: rem(14px);
        font-weight: 600;
        user-select: none;
        transition: background 50ms linear;

        .deleteAction {
          display: flex;
          gap: rem(8px);
          align-items: center;
          color: #EC4B5B;
          width: 100%;
          cursor: pointer;
          padding: rem(12px) rem(16px);
          border-radius: rem(10px);

          &:hover {
            background: #1E1C27;
          }
        }
        .copyAction{
          display: flex;
          gap: rem(8px);
          align-items: center;
          color: #FFFFFF;
          width: 100%;
          cursor: pointer;
          padding: rem(12px) rem(16px);
          border-radius: rem(10px);

          &:hover {
            background: #1E1C27;
          }
        }
      }
    }
  }
}

