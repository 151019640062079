@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  border-radius: rem(16px);
  border-bottom: rem(2px) solid #1E1C26;
  .content {
    width: 100%;
    &__Form{
      width: 100%;
      display: flex;
      padding: rem(12px) rem(20px);
      align-items: center;
      justify-content: space-between;
      gap: rem(8px);
      align-self: stretch;
    }

    &__Input {
      display: flex;
      height: rem(37px);
      padding: 0 rem(12px);
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 24px;
      background: #1E1C27;
      box-shadow: 0 0 rem(32px) 0 rgba(0, 0, 0, 0.25);
      border: none;
      color:#fff
    }

  }
  .content__SubmitForm{
    display: flex;
    width: rem(37px);
    height: rem(37px);
    justify-content: center;
    align-items: center;
    gap: rem(12px);
    border-radius: rem(200px);
    background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
    box-shadow: 0 rem(6px) rem(32px) 0 rgba(0, 0, 0, 0.25);
  }
}