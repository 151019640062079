@import './mixins';

.button {
  position: relative;
  text-align: center;
  border-radius: 200px;
  padding: rem(14px) rem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10px);
  transition: opacity 0.3s ease;
  overflow: hidden;
  font-size: rem(14px);
  font-weight: 400;
  line-height: rem(24px);

  @media (max-width: rem(767px)) {
    font-size: rem(14px);
    line-height: rem(18px);
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 200%;
    background: linear-gradient(127deg, #9E5992 0%, #6A19AB 37.27%, #7250D2 71.76%);
    top: 0;
    left: 0;
    transition: all 0.3s ease;
  }

  &:hover:before {
    top: -100%;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &Outlined {
    &:after {
      border-radius: 200px;
      content: '';
      z-index: 1;
      width: initial;
      height: initial;
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      background: #1F1D28;

    }

    span {
      position: relative;
      z-index: 2;
    }

    @media(max-width: 640px) {
      padding: rem(6px) rem(10x);
    }
  }

  &Text {
    padding: rem(5px);
    opacity: 0.5;
    background: transparent;
    font-size: rem(14px);
    font-weight: 500;
    line-height: rem(18px);

    &::before {
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  & > * {
    position: relative;
  }

  & > svg {
    @media (max-width: rem(767px)) {
      width: rem(18px);
      height: rem(18px);
    }
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(3px);
  padding: rem(6px);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(#c400d6, 0.1);
  }
}

.select {
  padding: rem(15px) rem(25px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  color: #ffffff;
  font-size: rem(16px);
  font-weight: 600;
  line-height: rem(20px);
  border: none;
  border-radius: rem(3px);
  @media (max-width: rem(767px)) {
    font-size: rem(12px);
    line-height: rem(16px);
  }

  option {
    color: #000000;
  }
}
