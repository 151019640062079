@import '~styles/mixins';
@import '~styles/variables';

.post {
  display: flex;
  justify-content: center;
  border-radius: rem(24px);
  width: 100%;
  padding: rem(40px);
  height: 100%;
  animation-name: appearing;
  animation-duration: 2s;

  @media (max-width: 1150px) {
    height: 85%;
  }

  @media (max-width: rem(840px)) {
    padding: rem(18px) 0 rem(26px) 0;
  }

  @media (max-width: 640px) {
    height: 100%;
  }

  @media (max-width: rem(350px)) {
    padding: rem(18px) rem(20px);
  }

  @keyframes appearing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .postImgWrapperTrending,
  .postImgWrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: rem(26px);

    @media (max-width: rem(768px)) {
      border-radius: rem(15px);
    }

    @media (max-width: rem(390px)) {
      border-radius: rem(15px);
    }

    @media (max-width: rem(320px)) {
      border-radius: rem(15px);
    }

    .postImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      background-color: rgba(0, 0, 0, 0.2);

      @media (max-width: 640px) {
        object-fit: contain;
      }
    }
  }

  .linkForVideo {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90%;

    @media (max-width: rem(600px)) {
      height: 85%;
    }

    @media (max-width: rem(370px)) {
      height: 80%;
    }
  }

  &User {
    z-index: 99;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: rem(10px);
    border-radius: 20px;
    margin-bottom: rem(18px);
    bottom: 0;

    @media (max-width: rem(400px)) {
      gap: rem(10px);
    }

    .avatarWrapper {
      overflow: hidden;
      width: rem(50px);
      height: rem(45px);
      border-radius: rem(200px);
      user-select: none;
      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      }
    }

    .nicknameWrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      gap: rem(5px);
      padding-top: rem(4px);

      @media (max-width: 380px) {
        justify-content: center;
        flex-direction: column;
      }
      .userInfo {
        .nickname {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(24px);
          transition: 0.3s;
          &:hover {
            color: hsla(248, 100%, 78%, 1);
          }

          @media (max-width: rem(400px)) {
            font-size: rem(15px);
            line-height: rem(18px);
          }
        }

        .date {
          width: rem(100px);
          font-weight: 400;
          font-size: rem(11px);
          line-height: rem(6px);
          color: hsla(240, 6%, 83%, 1);

          @media (max-width: rem(400px)) {
            font-size: rem(10px);
            line-height: rem(12px);
          }
        }
      }
    }
  }

  &FeedBack {
    padding-left: rem(22px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .postLikes {
      display: flex;
      transition: all 200ms ease-in-out;
      border-radius: rem(200px);
      flex-direction: column;
      gap: rem(10px);
      align-items: center;
      cursor: pointer;

      .postLikesIcon {
        display: flex;
        width: 50px;
        height: 50px;
        padding: 0px 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        transition: background-color 200ms linear;
        border-radius: 200px;
        background: #1e1c27;

        @media (max-width: 640px) {
          background: rgba(30, 28, 39, 0.6);
        }

        @media (max-width: 450px) {
          width: 35px;
          height: 35px;
        }
        &:hover {
          background: #282631;
        }
      }

      &Icon {
        fill: white;
        position: relative;

        .favoredIcon {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s;

          &.favored {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &Count {
        font-weight: 500;
        font-size: rem(15px);
        line-height: rem(18px);

        @media (max-width: rem(400px)) {
          font-size: rem(12px);
          line-height: rem(15px);
        }
      }
    }

    .postViews {
      display: flex;
      user-select: none;
      flex-direction: column;
      align-items: center;
      font-weight: 400;
      font-size: rem(15px);
      color: #948aa3;
      gap: rem(5px);
      line-height: rem(18px);
      color: hsla(240, 6%, 83%, 1);

      @media (max-width: rem(400px)) {
        font-size: rem(10px);
        line-height: rem(12px);
      }
    }
  }

  &Bottom {
    z-index: 99;
    position: absolute;
    bottom: 0;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      bottom: rem(-4px);
      transform: translateX(-50%);
      left: 50%;
      z-index: 0;
      width: 110%;
      height: rem(282px);
      background: linear-gradient(
        0deg,
        #13111c 0.33%,
        rgba(19, 17, 28, 0.72) 24.78%,
        rgba(19, 17, 28, 0) 63.45%
      );
      pointer-events: none;

      @media (max-width: 400px) {
        height: rem(102px);
      }
    }
    .postWrapper {
      position: relative;
      z-index: 99999;
      display: flex;
      width: 100%;
      padding: rem(22px);
      border-radius: rem(22px);
      flex-direction: column;

      @media (max-width: 450px) {
        padding: rem(5px);
      }

      .postCopy {
        width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: white;

        @media (max-width: rem(400px)) {
          width: rem(30px);
          height: rem(30px);
        }
        &:hover {
          background: #282631;
        }
      }
    }
  }
}

.admin {
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  .delete {
    padding: rem(10px);
    border: 1px solid hsla(295, 100%, 42%, 1);
    transition: 0.2s;
    border-radius: rem(15px);
    font-size: rem(16px);

    &:hover {
      background-color: hsla(295, 100%, 42%, 1);
    }
  }
}

.leftPart,
.rightPart {
  padding: rem(13px) rem(13px) 0 rem(13px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(30px);
}

.leftArrow,
.rightArrow {
  width: rem(50px);
  height: rem(50px);

  .icon {
    width: 100%;
    height: 100%;
    fill: rgb(255, 255, 255);
  }
}

.related {
  border-radius: rem(20px);
  padding: rem(10px);
  border: 1px solid hsla(295, 100%, 42%, 1);

  @media (max-width: rem(450px)) {
    font-size: rem(12px);
    line-height: rem(15px);
  }
}

.relatedContainer {
  display: flex;
  gap: rem(12px);
}

.imgWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.textStyleContainer {
  margin: rem(10px) 0;
}

.postImgContainer {
  position: relative;
}

.postTags {
  display: flex;
  flex-direction: row;
  gap: rem(6px);
}

.textExpanded {
  overflow: visible;
  -webkit-line-clamp: none;
  color: #d1d1d6;
  text-overflow: ellipsis;
  font-size: rem(15px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media (max-width: rem(400px)) {
    font-size: rem(12px);
    line-height: rem(15px);
  }
}

.text {
  overflow: hidden;
  color: #d1d1d6;
  text-overflow: ellipsis;
  font-size: rem(15px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &Style {
    position: relative;
    padding: rem(6px) rem(12px);
    border-radius: 30px;
    background: #282631;
    text-align: center;

    @media (max-width: 600px) {
      font-size: rem(12px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: rem(50px);
      padding: rem(1px);
      background: linear-gradient(160deg, #9e5992, #6a19ab, #3054bb);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }

  @media (max-width: rem(400px)) {
    font-size: rem(12px);
    line-height: rem(15px);
  }
}

.postFeedBack {
  gap: rem(25px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: fit-content;
  height: 100%;
}

.firstColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-right: rem(22px);

  @media (max-width: 640px) {
    display: none;
  }

  .navButtonPrev,
  .navButtonNext {
    display: flex;
    transition: background-color 200ms linear;
    width: rem(40px);
    height: rem(40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: rem(200px);
    background: #1e1c27;
    &:hover {
      background: #282631;
    }
  }
}

.secondColumn {
  width: rem(420px);
  height: 100%;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.thirdColumn {
  width: fit-content;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 640px) {
    position: absolute;
    right: 20px;
    bottom: 120px;
    z-index: 1;
  }

  @media (max-width: 380px) {
    bottom: 80px;
  }

  .volumeSliderContainer {
    display: flex;
    align-items: center;

    justify-content: center;
  }
}
.iconSetting {
  width: rem(24px);
  height: rem(24px);
  display: flex;
  align-items: center;
}

.descriptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.follow {
  font-weight: 400;
  font-size: rem(15px);
  line-height: rem(18px);
  padding: rem(5px) rem(10px);
  background: linear-gradient(
    116deg,
    #9e5992 3.98%,
    #6a19ab 58.61%,
    #3054bb 112.12%
  );
  border-radius: rem(25px);
  width: fit-content;
  transition: 0.3s;

  @media (max-width: 400px) {
    font-size: rem(12px);
  }

  &.followed {
    background: linear-gradient(
      116deg,
      #9e5992 3.98%,
      #6a19ab 58.61%,
      #3054bb 112.12%
    );
  }
}

.videoContainer {
  width: 100%;
  height: 100%;

  .statusVideo {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #13111cb2;
  }
}

.settingsPost {
  border-radius: 200px;
  background: #1e1c27;
  display: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  transition: background-color 200ms linear;
  @media (max-width: 640px) {
    background: rgba(30, 28, 39, 0.6) !important;
  }
  @media (max-width: 450px) {
    width: 35px;
    height: 35px;
  }
  &:hover {
    background: #282631;
  }
}

.playButton {
  width: 50px;
  height: 50px;
}

.buttonTextContainer {
  .buttonText {
    text-decoration: underline;
    transition: color 200ms linear;
    &:hover {
      color: #fff;
    }
  }
}
