@import '~styles/mixins';

.fileUpload {
  display: flex;
  align-items: center;
  position: relative;
  min-height: rem(300px);

  @media (max-width: 840px) {
    padding: 0 14px;
  }

  &Item {
    padding: 4px;
    border-radius: 24px;
    background: #1e1c27;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    cursor: pointer;

    $root: &;

    &:hover {
      #{$root}Wrapper {
        border-color: #fff;
      }
    }

    &Wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      gap: 10px;
      border-radius: 24px;
      border: 1px dashed #53525d;
      padding: 10px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      transition: 0.3s;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .uploadButton {
    pointer-events: none;
  }

  .item {
    $root: &;
    display: flex;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 24px;
    background: #1e1c27;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 4px;

    &Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }
}
