@import '~styles/mixins';

.postPage {
  width: 100%;
  padding-top: rem(40px);
  padding-bottom: rem(100px);
  //   height: 100%;
  
  @media (max-width: rem(840px)) {
    padding: rem(18px) 0 rem(26px) 0;
  }

  .goBackButton {
    width: rem(50px);
    height: rem(50px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: rem(20px);
  }

  .content {
    display: flex;
    justify-content: center;
    height: 100%;

    .postWrapper {
      width: 100%;
      height: 100%;
      max-width: rem(680px);

      .postImage {
        max-height: unset;
      }
    }
  }
}

.recommendations__swiper {
  margin-top: 25px;
  width: 100%;

  .recommendations__swiperItem {
    position: relative;
    max-width: 300px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      user-select: none;
      pointer-events: none;
    }

    @media (max-width: rem(550px)) {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

.link {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.leaveComment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: rem(25px) 0;

  @media (max-width: rem(500px)) {
    gap: 10px;
  }

  .avatarWrapper {
    min-width: rem(60px);
    height: rem(60px);

    img {
      border-radius: 15px;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: rem(500px)) {
        border-radius: 10px;
      }
    }

    @media (max-width: rem(500px)) {
      min-width: rem(45px);
      height: rem(45px);
    }
  }

  .comment {
    max-width: 390px;
    width: 100%;
    border-radius: 30px;
    border: 1px solid #4628ff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: rem(16px) 0;

    .comment__text {
      color: #FFF;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
    }

    @media (max-width: rem(500px)) {
      padding: rem(8px) 0;
    }
  }
}

.comments {
  // max-width: rem(300px)
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: rem(20px);
}