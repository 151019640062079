@import '~styles/mixins';

.fixed {
  margin-top: rem(22px);
  width: 100%;

  @media (max-width: 765px) {
    display: grid;
    position: fixed;
    max-width: rem(300px);
    bottom: rem(130px);
    place-self: center;
    z-index: 999;
  }

  .button {
    height: rem(55px);
    width: 100%;
    pointer-events: all;
    border-radius: rem(30px);
    opacity: 1 !important;

    &:disabled {
      pointer-events: none;
    }
  }

  .buttonContent {
    width: 100%;
    display: flex;
    align-items: center;
    gap: rem(8px);
    color: #fff;
    text-align: center;
    font-size: rem(18px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
