@import '~styles/mixins';
@import '~styles/variables';

.navbar {
  position: sticky;
  top: rem(90px);
  height: calc(var(--height) - var(--content-top) - rem(32px));
  // overflow: auto;
  z-index: 10;

  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  display: flex;
  height: 98%;
  flex-direction: column;

  .links {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(30px);

    @media (max-width: 1500px) {
      gap: rem(10px);
    }
  }

  .categoryTitle {
    color: #e7e7e7;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: rem(10px);
  }
}

.bottomPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem(5px);
  padding-top: rem(7px);

  .navbarItem {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: rem(6px);
    padding: rem(6px) rem(16px);
  }

  .navbarItemForTexts {
    display: flex;
    gap: rem(5px);
    flex-direction: column;
  }

  .navbarItemForTexts.unimportant {
    @media (max-height: 765px) {
      display: none;
    }
  }
}

.navbarCategoryItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem(20px);

  .backNavbarItem {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: rem(15px);
    border-radius: rem(200px);
    transition: all 200ms ease-in-out;
    padding: rem(15px) rem(16px);
    margin-bottom: rem(41px);
    background: rgba(235, 235, 245, 0.05);

    &:hover {
      background-color: #282631;
    }
  }

  .navbarItem {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: rem(15px);
    width: 100%;
    border-radius: rem(200px);
    transition: all 200ms ease-in-out;
    padding: rem(15px) rem(16px);

    &:hover {
      background-color: #282631;
    }

    &Modal {
      display: block;
    }

    &__icon {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__title {
      color: #fff;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &__button {
      display: flex;
      align-items: center;
      gap: rem(15px);
      width: 100%;
    }

    &__arrowIcon {
      flex-grow: 1;
      text-align: end;

      .arrowIcon {
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;

        &.rotateIcon {
          transform: rotate(-90deg);
        }
      }
    }

    &__modal {
      pointer-events: none;
      opacity: 0;
      z-index: 0;
      transition: all 0.3s;

      &.modalActive {
        pointer-events: all;
        opacity: 1;
        z-index: 1;
      }
    }
  }

  .navbarItemForTexts {
    display: flex;
    gap: rem(5px);
    flex-direction: column;
  }

  .navbarItemForTexts.unimportant {
    @media (max-height: 765px) {
      display: none;
    }
  }
}

.link {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.navbarItem.active {
  background: linear-gradient(160deg, #9e5992, #6a19ab, #3054bb);
}

.photoArtContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.footerTitle {
  color: #d1d1d6;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbarItemForTextss {
  padding: rem(2px) rem(16px);
}
