@import '~styles/mixins';

.categoryContainer {
  padding: rem(10px) rem(14px);
  border-radius: 14px;
  background-color: #1e1c27;
  transition: all 0.3s ease;
  &:hover {
    background-color: #282631;
  }
}
.activeCategory {
  background: linear-gradient(
    116deg,
    #9e5992 3.98%,
    #6a19ab 58.61%,
    #3054bb 112.12%
  );
}
.categoryText {
  color: #fff;
  font-size: rem(15px);
  font-weight: 500;
  @media (max-width: rem(1150px)) {
    font-size: rem(13px);
  }
}
