@import '~styles/mixins';

.advancedSettingsContainerUnActive {
  transition: background linear 200ms;
  width: 100%;
  height: 100%;
  &:hover {
    background: #1E1C27;
  }
}

.advancedSettingsContainer {
  margin-top: rem(22px);
  height: 100%;
  display: flex;
  width: 100%;;
  flex-direction: column;
  align-items: flex-start;
  border-radius: rem(26px);
  border: 2px solid #1F1D28;
  box-shadow: 0 0 rem(22px) 0 rgba(0, 0, 0, 0.15);
  .wrapperAdvancedSettings {
    padding: rem(26px) rem(22px) rem(26px) rem(22px);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .stylesTitle{
      font-weight: 600;
      font-size: 16px;
      line-height: rem(24px);
      z-index: 2;
    }

    .leftPart {
      color: #FFF;
      font-weight: 600;
      font-size: rem(20px);
      line-height: rem(24px);
    }

    .rightPart {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: rem(12px);

      .displayStyle {
        display: flex;
        padding: rem(8px) rem(6px);
        justify-content: center;
        align-items: center;
        font-weight: 500;
        gap: rem(12px);
        border-radius: 200px;
        background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
      }

      .arrowIcon {
        transition: transform 0.2s ease-in-out;
        transform: rotate(0deg);
      }

      .arrowIconOpen {
        transform: rotate(180deg);
      }
    }
  }
}


.settings {
  padding: 0 rem(22px) rem(42px) rem(22px);

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(32px);
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}


.settingsEnter {
  opacity: 0;
  transform: scale(0);
}

.settingsEnterActive {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms ease-in-out, transform 200ms ease-in-out;
}

.settingsExit {
  opacity: 1;
}

.settingsExitActive {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}
