@import '~styles/mixins';

.history {
  width: 100%;
}

.grid {
  display: flex;
  flex-wrap: nowrap;
  gap: rem(20px);
  justify-content: center;
  align-items: start;
  padding-bottom: rem(15px);
  height: fit-content;
}

.wrapper {
  z-index: 2;
  margin-top: rem(10px);
  position: relative;
  border-radius: rem(25px);
  padding-top: 100%;
  animation-name: appearing;
  animation-duration: 2s;
  width: 100%;
  height: fit-content;
  @keyframes appearing {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: rem(25px);
  left: 0;
  min-width:rem(220px);
  max-height:rem(220px);
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  min-width:rem(220px);
  max-height:rem(220px);
  right: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.loadMoreButton {
  margin-top: rem(15px);
  padding: rem(10px) rem(20px);
  margin-left: auto;
  margin-right: auto;
  background: transparent;
  span {
    font-size: rem(16px);
    line-height: rem(20px);
  }
  @media (max-width: rem(575px)) {
    margin-top: rem(10px);
    span {
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
}



.loaderWrapper {
  position: relative;
  width: 100%;
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}


.historyListWrapper {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  margin: rem(10px) 0;
}
