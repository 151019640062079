@import '~styles/mixins';

.promptsContainerUnActive {
  transition: background linear 200ms;

  &:hover {
    background: #1E1C27;
  }
}

.promptsContainer {
  position: relative;
  margin-top: rem(22px);
  display: flex;
  width: 100%;;
  flex-direction: column;
  align-items: flex-start;
  border-radius: rem(26px);
  border: rem(2px) solid #1F1D28;
  box-shadow: 0 0 rem(22px) 0 rgba(0, 0, 0, 0.15);

  .wrapperAdvancedSettings {
    display: flex;
    padding: rem(16px);
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .leftPart {
      color: #FFF;
      font-size: 14px;
      font-weight: 500;
    }

    .rightPart {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: rem(12px);


      .arrowIcon {
        transition: transform 0.2s ease-in-out;
        transform: rotate(0deg);
      }

      .arrowIconOpen {
        transform: rotate(180deg);
      }
    }
  }
}


.content  {
  padding: 0 rem(16px) rem(16px) rem(16px);
  width: 100%;
  display: flex;
  flex-direction: column;
}


.settingsEnter {
  opacity: 0;
  transform: scale(0);
}

.settingsEnterActive {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms ease-in-out, transform 200ms ease-in-out;
}

.settingsExit {
  opacity: 1;
}

.settingsExitActive {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}
