@import '~styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: rem(14px);
  .heading {
    color: rgba(235, 235, 245, 0.30);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .switch {
    position: relative;
    display: flex;
    
    gap: rem(12px);
    background: #191724;
    @media (max-width:400px) {
      flex-wrap: wrap;
    }
    &:before {
      content: '';
      position: absolute;
      top: rem(1px);
      bottom: rem(1px);
      left: rem(1px);
      right: rem(1px);
      background-color: var(--background-color);
      z-index: 0;
    }


    &Button {
      display: block;
      cursor: pointer;
      width: 50%;
      @media (max-width:400px) {
        width: 100%;
      }
    }

    &Input {
      &:checked ~ .switchBody {
        &::before {
          opacity: 1;
        }
      }
    }

    &Body {
      position: relative;
      display: block;
      padding: rem(10px);
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
        border-radius: rem(14px);
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      & > * {
        position: relative;
        z-index: 1;
      }
    }

    &Filler {
      position: absolute;
      background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
      border-radius: rem(14px);
      z-index: 0;
    }

    &Heading {
      display: block;
      font-weight: 500;
      font-size: rem(17px);
      line-height: rem(21px);
    }

    &Text {
      white-space: nowrap;
      color: rgba(235, 235, 245, 0.50);
      font-size: rem(16px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

