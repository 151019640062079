@import '~styles/mixins';

.event {
  display: flex;
  flex-direction: column;
  gap: rem(6px);
  .userInfo{
    gap: rem(8px);
    display: flex;
    flex-direction: row;
    align-items: center;
    .nickname {
      color: var(--White, #FFF);
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: rem(7px);
      line-height: normal;
    }

    .date {
      color: var(--Button-Transperent, rgba(235, 235, 245, 0.30));
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
    }
  }

  .whatHeDid {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
