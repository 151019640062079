// ChatThemesSelect.module.scss
@import '~styles/mixins';

.chatThemesSelect {
  position: relative;
  background: #282631;
  padding: 0 14px;
  max-width: rem(210px);
  width: 100%;
  user-select: none;
  min-height: rem(45px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .themeName {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      width: 100%;
      white-space: nowrap;
    }

    .icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: 100%;
      .iconSetting {
        padding-top: 5px;
        height: 100%;
        display: flex;
        transition: all 100ms linear;
        text-align: center;
        align-items: center;
        justify-content: center;
      }

      .active {
        transform: rotate(-180deg);
      }
    }
  }

  .themes {
    position: absolute;
    top: 100%;
    max-height: rem(310px);
    overflow-y: auto;
    left: 0;
    width: 100%;
    background: #282631;
    padding-bottom: 14px;
    border-radius: 0 0 rem(23px) rem(23px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .theme {
      color: #ffffff;
      padding: 10px 14px;
      width: 100%;
      display: flex;
      justify-content: start;
      font-size: 14px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &.active {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.chatThemesSelectActive {
  border-radius: rem(23px) rem(23px) 0 0;
}

.chatThemesSelectDisabled {
  border-radius: rem(102px);
}
