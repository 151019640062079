@import '~styles/mixins';

.subscribe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(10px);
  padding: rem(17px) rem(18px);
  border-radius: rem(14px);
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
  background: #191724;

  &Left,
  &Right {
    display: flex;
    align-items: center;
    gap: rem(17px);
  }

  &Left {
    &Title {
      font-weight: 500;
      font-size: rem(18px);
      text-align: center;
      color: #b8b7ba;

      @media (max-width: 690px) {
        font-size: rem(16px);
      }
    }

    &Icon {
      min-width: rem(26px);
      min-height: rem(26px);
    }
  }

  &Right {
    &Date {
      font-size: rem(15px);
      text-align: center;
      color: rgba(255, 255, 255, 0.7);
      @media (max-width: 690px) {
        font-size: rem(13px);
      }
    }
    &Button {
      padding: rem(11px) rem(18px);
      font-weight: 500;
      font-size: rem(15px);
      text-align: center;
      color: #ec4b5b;
      border-radius: rem(200px);
      background: rgba(235, 235, 245, 0.05);
      box-shadow: 0 rem(13px) rem(34px) 0 rgba(0, 0, 0, 0.1);

      @media (max-width: 690px) {
        font-size: rem(13px);
      }
    }
  }
}
