@import '~styles/mixins';

.header {
  display: flex;
  justify-content: space-between;
  height: rem(60px);
  align-items: center;
  width: 100%;
  z-index: 99;
  position: fixed;
  background: #13111c;
  left: 0;
  top: 0;
  padding: 0 rem(16px);
  .heading {
    gap: rem(10px);
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 rem(12px);
  }

  .rightPart,
  .leftPart {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 140px;
    align-self: center;
    justify-content: flex-end;
    gap: rem(12px);

    .proText {
      color: #fff;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .leftPart {
    justify-content: flex-start;
  }

  .iconContainer {
    position: relative;
  }
  .icon,
  .settingsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20px);
    height: rem(20px);
  }
}
