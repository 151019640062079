@import '~styles/mixins';

.gridWrapper {
  position: relative;
  width: 100%;

  .albumActions {
    position: absolute;
    right: 0;
    bottom: 100%;
    z-index: 2;

    @media (max-width: 940px) {
      top: rem(20px);
      left: 0;
      padding: 0 14px;
    }

    .action {
      position: relative;
      display: flex;
      gap: rem(11px);

      .textarea {
        width: rem(240px);
        height: auto;
        max-height: rem(200px);
        padding: rem(17px) rem(22px);
        color: #b0b0b8;
        font-size: rem(18px);
        font-weight: 400;
        font-family: 'Montserrat';
        background-color: #1e1c27;
        border-radius: rem(26px);
        outline: none;
        border: none;
        resize: none;

        &::placeholder {
          color: #b0b0b8;
        }
      }

      .createText,
      .conceptNameEdit {
        padding: rem(16px) rem(22px);
        width: max-content;
        height: max-content;
        font-size: rem(17px);
        border-radius: rem(200px);
        border: 2px solid transparent;
        background:
          linear-gradient(#13111c, #13111c) padding-box,
          linear-gradient(
              116deg,
              #9e5992 3.98%,
              #6a19ab 58.61%,
              #3054bb 112.12%
            )
            border-box;
      }

      .conceptNameEdit {
        padding: rem(16px) rem(22px);

        span {
          font-size: rem(17px);
        }
      }

      .button {
        margin: 0;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(22px);
    padding-top: rem(20px);

    @media (max-width: rem(950px)) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: rem(940px)) {
      padding-top: rem(100px);
    }

    @media (max-width: rem(700px)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: rem(430px)) {
      grid-template-columns: 1fr;
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
