@import '~styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  gap: rem(12px);

  &Image {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: rem(5px);

    &Avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid #282535;
    }

    &Button {
      flex: 1;
      height: 50px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &Input {
    border-radius: 24px;
    font-weight: 400;
  }
  &ForgotPassword {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.4;
    line-height: normal;
  }

  .logOut {
    align-self: center;
    width: 100%;
  }

  &Submit {
    margin-top: rem(25px);
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
  }

  .checkedLabel {
    display: flex;
    align-items: center;
    gap: rem(18px);
    color: #948aa3;
    font-size: 14px;
    font-weight: 400;

    &Input {
      appearance: none;
      min-width: rem(25px);
      max-width: rem(25px);
      aspect-ratio: 1;
      border-radius: 6px;
      border: 2px solid #383744;
      background: transparent;
      cursor: pointer;

      &:checked {
        border: 2px solid #fff;
      }

      &:checked::before {
        content: url('../../components/icons/CheckedIcon.svg');
        display: block;
        font-size: 16px;
        vertical-align: middle;
        text-align: center;
      }
    }

    &White {
      color: #fff;
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  .wrapper {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .socialHeading {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.formSubm {
  margin-top: rem(100px);
}
.header {
  margin-bottom: rem(28px);
}
