
@import '~styles/mixins';

.heading {
  color: rgba(235, 235, 245, 0.30);
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.powerSelection {
    padding-top: rem(20px);
    display: flex;
    flex-direction: column;
    gap: rem(20px);

    .rangeWrapper {
      position: relative;
      @media (max-width: 840px) {
          margin: 0px 10px;
      }

      .minLabel {
        position: absolute;
        top: 150%;
        left: 0;
        color:  #D1D1D6;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;


      }
      .maxLabel {
        position: absolute;
        right: 0;
        top: 150%;
        color:  #D1D1D6;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

      }

      .rangeCircle {
        width: rem(10px);
        height: rem(10px);
        border-radius: 50%;
        background-color: hsla(295, 100%, 42%, 1);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;

        &:nth-child(1) {
          left: 0;
        }
        &:nth-child(2) {
          left: 25%;
        }
        &:nth-child(3) {
          left: 50%;
        }
        &:nth-child(4) {
          left: 75%;
        }
        &:nth-child(5) {
          left: 100%;
        }
      }
    }
  }

.line {
  position: absolute;
  height: 100%;
  width: 2px;
  z-index: 1;
  background-color: #2E2C37;
  top: 0;
}

