@import '~styles/mixins';

.inputContainer {
  position: relative;
  height: rem(60px);
  &LeftPadded {
    .input {
      padding-left: rem(50px);
    }
  }
  &RightPadded {
    .input {
      padding-right: rem(50px);
    }
  }
  & > svg {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:first-child {
      left: rem(15px);
    }
    &:last-child {
      right: rem(15px);
    }
  }
}

.input {
  position: relative;
  width: 100%;
  z-index: 1;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  border-radius: rem(26px);
  background: #1E1C27;
  box-shadow: 0 0 rem(32px) 0 rgba(0, 0, 0, 0.25);
  font-size: rem(16px);
  font-style: normal;
  display: flex;
  height: rem(142px);
  min-height: rem(52px);
  max-height: rem(202px);
  padding: rem(16px) rem(22px);
  justify-content: center;
  align-items: flex-start;
  gap: rem(12px);
  align-self: stretch;
  resize: none;
  font-weight: 400;
  line-height: normal;
  border: none;
  transition: background 0.2s ease;


  &::placeholder {
    color: #B0B0B8;
  }
}
