@import '~styles/mixins';

.assetContainer {
  display: flex;
  position: relative;
  border-radius: rem(26px);
  padding: rem(16px);
  gap: rem(16px);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: #191724;
  width: 100%;

  .assetImageWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .assetImage {
    width: 100%;
    height: 100%;
    min-height: rem(120px);
    border-radius: rem(26px);
    object-fit: cover;
  }

  .assetActions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    gap: rem(12px);
    user-select: none;

    .shareAction {
      color: #FFF;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.10);
      display: flex;
      height: rem(42px);
      padding: 0 rem(16px);
      justify-content: center;
      align-items: center;
      gap: rem(8px);

      &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .actions {
      color: #FFF;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: rgba(235, 235, 245, 0.05);
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.10);
      display: flex;
      height: rem(42px);
      padding: 0 rem(12px);
      justify-content: center;
      align-items: center;
      transition: background 50ms linear;

      &:hover {
        background: #282631;
      }
    }
  }

  .favoriteWrapper {
    position: absolute;
    top: rem(10px);
    left: rem(10px);
    height: rem(40px);
    width: rem(40px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .fullScreenContainer,
  .compareContainer {
    transition: opacity 0.5s ease, visibility 0.5s;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(50px);
    border-radius: 20px;
    padding: rem(6px);
    transition: background 50ms linear;

    @media (max-width: 650px) {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.50);
    }
  }

  .socialNetworksContainer {
    position: absolute;
    width: 90%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(72px);
    bottom: 0;
    padding: 6px rem(16px);
    border-radius: 8px;
    background: #282631;

    &::before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 5%;
      border-left: rem(12px) solid transparent;
      border-right: rem(12px) solid transparent;
      border-top: rem(12px) solid #282631;
      width: 0;
      height: 0;
    }

    .socials {
      display: flex;
      flex-direction: row;
      gap: rem(12px);
    }

    .iconSocial {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fullScreenContainer {
    top: 0;
    right: 0;
    margin: rem(30px);
    border-radius: 20px;
  }

  .compareContainer {
    top: 0;
    border-radius: 20px;
    left: 0;
    margin: rem(30px);

  }


}

.cardAct {
  display: flex;
  gap: rem(12px);
}

.imageCompareWrapper {
  width: 100%;
  height: 100%;
}

.iconSetting {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsEnter {
  opacity: 0;
  transform: scale(0);
}

.settingsEnterActive {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms ease-in-out, transform 200ms ease-in-out;
}

.settingsExit {
  opacity: 1;
}

.settingsExitActive {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}
