@import '~styles/mixins';

.heading {
    font-weight: 600;
    font-size: rem(20px);
    line-height: rem(24px);
}

.input {
    margin-top: rem(20px);
}