@import '~styles/mixins';

.loadingStage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finishingStage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.navigationPanel {
  padding: rem(27px) 0 rem(26px) 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: rem(840px)) {
    padding: rem(3px) 0 rem(26px) 0;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: rem(1150px)) {
    gap: rem(20px);
  }

  @media (max-width: rem(1100px)) {
    display: flex;
    flex-direction: column;
  }
}

.controlPanel {
  background-color: hsla(251, 27%, 11%, 1);
  border-radius: rem(30px);
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: rem(46px) rem(50px) rem(43px) rem(50px);

  @media (max-width: rem(767px)) {
    padding: rem(26px) rem(30px) rem(23px) rem(30px);
  }

  .requestWrapper {
    height: rem(124px);
    position: relative;

    &LeftPadded {
      .input {
        padding-left: rem(50px);
      }
    }

    &RightPadded {
      .input {
        padding-right: rem(50px);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
      border-radius: rem(30px);
    }

    .requestInput {
      height: rem(124px);
      position: relative;
      width: 100%;
      z-index: 1;
      color: hsla(240, 6%, 83%, 1);
      text-align: center;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(22px);
      background-color: hsla(251, 27%, 11%, 1);
      border: none;
      padding: rem(29px) rem(42px);
      border-radius: rem(30px);
      outline: rem(1px) solid transparent;
      transition: outline-color 0.3s ease;
      resize: none;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &:focus-visible {
        outline: rem(1px) solid #c400d6;
      }

      @media (max-width: rem(767px)) {
        padding: rem(22px) rem(22px);
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: rem(50px) 1fr rem(50px);
    gap: rem(15px);
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    max-width: rem(380px);
    padding: rem(30px) 0 rem(90px) 0;

    @media (max-width: rem(767px)) {
      padding: rem(30px) 0 rem(30px) 0;
    }

    .button {
      display: flex;
      flex-direction: row;
      gap: rem(10px);
      align-items: center;
      justify-content: center;
      border-radius: rem(20px);
      background-color: hsla(0, 0%, 100%, 0.1);
      width: 100%;
      height: rem(40px);
      font-weight: 600;
      font-size: rem(15px);
      line-height: rem(18px);
    }

    .iconButton {
      border-radius: rem(20px);
      background-color: hsla(0, 0%, 100%, 0.1);
      width: rem(50px);
      height: rem(40px);
    }
  }
}

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: rem(767px)) {
    margin-top: rem(15px);
  }

  &Button {
    padding: rem(12px) rem(6px);
    // height: 100%;
  }
}

.copyButton {
  margin-left: rem(8px);
  display: inline-block;
}

.rightPart {
  display: flex;
  flex-direction: column;
  gap: rem(40px);

  @media (max-width: rem(1100px)) {
    flex-direction: column-reverse;
  }

  @media (max-width: rem(767px)) {
    gap: rem(20px);
  }
}

.privateSubs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: rem(15px);

  .privateSubsContent {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: rem(767px)) {
      font-size: rem(15px);
      line-height: rem(18px);
    }

    @media (max-width: rem(450px)) {
      font-size: rem(12px);
      line-height: rem(15px);
    }
  }

  @media (max-width: rem(767px)) {
    margin-bottom: 0;
  }

  @media (max-width: rem(450px)) {
    margin-bottom: 0;
  }
}

.fullScreenContainer {
  transition:
    opacity 0.5s ease,
    visibility 0.5s;
  z-index: 9;
  display: flex;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(50px);
  margin: rem(30px);
  border-radius: 20px;
  padding: rem(8px);
  top: 0;
  right: 0;
  transition: background 50ms linear;
  @media (max-width: 650px) {
    visibility: visible;
    opacity: 1;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}
