@import '~styles/mixins';

.inputContainer {
  position: relative;
  height: rem(60px);
  &LeftPadded {
    .input {
      padding-left: rem(50px);
    }
  }
  &RightPadded {
    .input {
      padding-right: rem(50px);
    }
  }
  & > svg {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:first-child {
      left: rem(15px);
    }
    &:last-child {
      right: rem(15px);
    }
  }
}

.input {
  height: rem(60px);
  position: relative;
  width: 100%;
  z-index: 1;
  color: #B0B0B8;
  font-family: 'Montserrat', sans-serif;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #1E1C27;
  border: none;
  padding: 14px 20px;
  border-radius: rem(12px);
  transition: background 0.2s ease;


  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}
