@import '~styles/mixins';

.container {
  &:not(:last-child) {
    margin-bottom: rem(16px);
  }

  border-radius: 24px;
  background: #191724;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 14px;
}

.reply {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  &Icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  &Image {
    max-width: 100%;
    border-radius: 6px;
    width: rem(42px);
    height: rem(42px);
  }

  &Text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    align-self: stretch;
    overflow: hidden;
    color: var(--Gray-Text, #d1d1d6);
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  &Footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
  }

  &Image {
    max-width: 100%;
    border-radius: 24px;
  }

  &Text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    flex: 1 0 0;
    overflow: hidden;
    color: var(--Gray-Text, #d1d1d6);
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }

  &Tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }

  &Tag {
    position: relative;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;

    border: 1px solid transparent;
    background:
      linear-gradient(#191724, #191724) padding-box,
      linear-gradient(116deg, #9e5992 3.98%, #6a19ab 58.61%, #3054bb 112.12%)
        border-box;

    color: var(--White, #fff);
    font-size: 12px;
    font-weight: 500;

    &Filled {
      background: var(
        --Gradient-2,
        linear-gradient(116deg, #9e5992 3.98%, #6a19ab 58.61%, #3054bb 112.12%)
      );
      border: none;
    }
  }
}
