@import '~styles/mixins';

.container {
  $root: this;
  width: 100%;
  height: auto;
  margin: rem(23px) 0;
  display: flex;
  align-items: end;
  justify-content: end;
  padding: rem(16px) rem(22px);

  &__Another {
    justify-content: start;
    align-items: start;
  }

  .content {
    width: auto;
    display: inline-flex;
    height: auto;
    gap: rem(22px);
    align-items: end;
    justify-content: end;
    flex-direction: row;

    .loaderContainer {
      display: flex;
      gap: rem(12px);
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: rem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__MessageContainer {
      display: flex;
      height: auto;
      max-width: rem(660px);
      padding: rem(16px) rem(16px) rem(10px) rem(16px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: rem(6px);
      flex: 1 0 0;
      border-radius: rem(24px) rem(24px) rem(6px) rem(24px);
      background: #272437;
      box-shadow: 0 rem(6px) rem(22px) 0 rgba(0, 0, 0, 0.25);

      &__UserMessage {
        color: #fff;
        font-size: rem(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: break-word;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-word;
      }

      &__InfoMessage {
        color: #93919b;
        font-size: rem(12px);
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: rem(6px);
      }
    }

    &__User {
      height: 100%;
      width: auto;
      display: inline-flex;
      align-items: end;

      @media (max-width: 640px) {
        display: none;
      }

      &__Avatar {
        width: rem(42px);
        height: rem(42px);
        flex-shrink: 0;
        border-radius: rem(202px);
      }
    }
  }
}

.container__Another {
  .content {
    flex-direction: row-reverse;
  }
  .content__MessageContainer {
    border-radius: rem(24px) rem(24px) rem(24px) rem(6px);
    background: #191724;
  }
}

.selectedMessage {
  background: #1e1c27;
}

.selectedMessageAlone {
  background: #1e1c27 !important;
}

.selectedMessageDefault {
}
