@import '~styles/mixins';
@import '~styles/variables';

.mobileContent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $bottomHeight;
  border-radius: 20px 20px 0px 0px;
  border-top: 1px solid #191724;
  background: rgba(19, 17, 28, 0.8);
  backdrop-filter: blur(15px);
  display: flex;
  padding: 10px 15px 30px 15px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  z-index: 30;

  @media (min-width: rem(1151px)) {
    display: none;
  }
  @media (max-width: rem(500px)) {
    z-index: 1000;
  }
  // @media (max-width: rem(390px)) {
  //   padding: 0 rem(10px);
  // }

  .nav {
    width: 100%;
  }

  .nav__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: rem(5px);
    font-weight: 600;
    font-size: rem(12px);
    line-height: rem(15px);
    list-style: none;
    max-width: rem(567px);
    margin: 0 auto;

    @media (max-width: rem(400px)) {
      & {
        font-size: rem(12px);
        line-height: rem(12px);
      }
    }
  }

  .nav__elem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(5px);
    width: rem(69px);
    height: rem(69px);
    border-radius: rem(20px);
    text-align: center;
    border: 1px solid transparent;
    position: relative;
    transition: 0.3s;

    @media (max-width: rem(400px)) {
      & {
        width: rem(64px);
        height: rem(64px);
      }
    }
  }

  .icon {
    width: rem(31px);
    height: rem(31px);
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #948aa3;
  left: 0;
  top: 0;
  opacity: 0;
}

.active {
  color: #ffffff;
}

.noActive {
  color: #948aa3;
}
