@import '~styles/mixins';
@import '~styles/variables';

.layout {
  min-height: var(--height, 100vh);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: rem(292x) 1fr;
  gap: 32px;

  @media (max-width: rem(1160px)) {
    grid-template-columns: 1fr;
  }

  .leftPart {
    display: flex;
    width: rem(300px);
    padding: rem(32px) rem(22px);
    flex-direction: column;
    gap: rem(42px);

    .logo {
      width: 100%;
      padding: 0 rem(16px);
      display: flex;
      justify-content: start;
      align-items: center;
      gap: rem(10px);
      flex-direction: row;
      min-height: rem(72px);
      height: auto;
      position: sticky;
      top: 0;
      background: var(--background-color);
      z-index: 1;

      &Image {
        display: block;
        width: rem(34px);
        height: auto;
        object-fit: contain;
        @media (max-width: rem(800px)) {
          width: rem(25px);
        }
      }

      &Text {
        font-size: rem(22px);
        font-weight: 600;
        line-height: rem(30px);
        @media (max-width: rem(800px)) {
          font-size: rem(20px);
          line-height: rem(24px);
        }
      }
    }

    @media (max-width: rem(1160px)) {
      display: none;
    }
  }
}

.rightPart {
  padding: rem(32px) 0 0 rem(21px);
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: rem(72px) 1fr;
  gap: rem(42px);
  max-width: var(--content-width);

  @media (max-width: rem(1150px)) {
    height: calc(100% + 110px);
  }
  @media (max-width: 680px) {
    grid-auto-rows: initial;
  }
  @media (max-width: rem(840px)) {
    padding: 0 0 0 rem(15px);
  }
  @media (max-width: 640px) {
    height: calc(100% - 120px);
    margin-top: rem(60px);
    padding: 0 0 rem(210px) 0;
  }
}

.rightPartContent {
  max-width: var(--content-width);
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.notificationss {
  display: none;

  @media (max-width: 930px) {
    display: block;
  }
}
