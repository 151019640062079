@import '~styles/mixins';

.heading {
  font-weight: 600;
  font-size: rem(18px);
  line-height: rem(24px);
}

.input {
  margin-top: rem(20px);
  display: flex;
  height: auto;
  min-height: 50px;
  max-height: 200px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}