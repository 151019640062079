@import '~styles/mixins';

.container {
  display: flex;
  width: 100%;
  padding: rem(18.5px);
  justify-content: center;
  align-items: flex-end;
  gap: rem(12px);
  align-self: stretch;
  border-top: 2px solid #1E1C26;

  .content {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: rem(12px);
    width: 100%;

    .iconSetting {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .wrapperInput {
      width: 100%;

      .input {
        font-family: Montserrat, sans-serif;
        resize: none;
        display: flex;
        min-height: rem(52px);
        max-height: rem(202px);
        padding: rem(18px) rem(22px);
        width: 100%;
        border: none;
        color: #ffff;
        justify-content: center;
        align-items: flex-end;
        gap: rem(22px);
        flex: 1 0 0;
        border-radius: rem(26px);
        background: #1E1C27;
        box-shadow: 0 0 rem(32px) 0 rgba(0, 0, 0, 0.25);

        @media(max-width: 840px) {
          max-height: 50px;
        }
      }
    }


    .wrapperButtonPaid {
      display: flex;
      width: rem(52px);
      height: rem(50px);
      justify-content: center;
      align-items: center;
      border-radius: 200px;
      box-shadow: 0 rem(6px) rem(32px) 0 rgba(0, 0, 0, 0.25);
      gap: rem(12px);
    }

    .active {
      background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
    }

    .disabled {
      background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
      opacity: 0.4;
    }

    .wrapperButtonUnPaid {
      display: flex;
      width: rem(52px);
      height: rem(50px);
      justify-content: center;
      align-items: center;
      border-radius: 200px;
      opacity: 0.4;
      background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
      box-shadow: 0 rem(6px) rem(32px) 0 rgba(0, 0, 0, 0.25);
      gap: rem(12px);
    }
  }
}