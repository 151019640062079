@import '~styles/mixins';

.heading {
  color: #fff;
  font-size: rem(18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.photoSelection {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  .photoSelection__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: rem(20px);
    justify-content: start;
    align-items: start;
    .photoSelection__item {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      aspect-ratio: 1;

      list-style: none;
      .addImage {
        position: relative;
        text-align: center;
        height: 45px;
        margin-top: 15px;
        width: 100%;
        border-radius: rem(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #c400d6;
        transition: opacity 0.3s ease;
        &:not(:disabled) {
          background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
        }
        &:disabled {
          pointer-events: none;
        }
      }
    }

    @media (max-width: rem(855px)) {
      grid-template-columns: repeat(auto-fill, rem(220px));
      grid-template-rows: rem(280px);
      gap: rem(20px);
    }
    @media (max-width: rem(720px)) {
      grid-template-columns: repeat(auto-fill, rem(190px));
      grid-template-rows: rem(250px);
      gap: rem(20px);
    }
    @media (max-width: rem(605px)) {
      grid-template-columns: repeat(auto-fill, rem(180px));
      grid-template-rows: rem(240px);
      gap: rem(20px);
    }
    @media (max-width: rem(550px)) {
      grid-template-columns: repeat(auto-fill, rem(160px));
      grid-template-rows: rem(220px);
      gap: rem(10px);
    }
    @media (max-width: rem(480px)) {
      grid-template-columns: repeat(auto-fill, rem(140px));
      grid-template-rows: rem(200px);
      gap: rem(10px);
    }
    @media (max-width: rem(425px)) {
      grid-template-columns: repeat(auto-fill, rem(130px));
      grid-template-rows: rem(190px);
      gap: rem(10px);
    }
    @media (max-width: rem(400px)) {
      grid-template-columns: repeat(auto-fill, rem(120px));
      grid-template-rows: rem(180px);
      gap: rem(10px);
    }
    @media (max-width: rem(370px)) {
      grid-template-columns: repeat(auto-fill, rem(110px));
      grid-template-rows: rem(170px);
      gap: rem(10px);
    }
    @media (max-width: rem(340px)) {
      grid-template-columns: repeat(auto-fill, rem(105px));
      grid-template-rows: rem(165px);
      gap: rem(10px);
    }
  }
}

.historyListWrapper {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  margin: rem(10px) 0;
}
