@import '~styles/mixins';

.addImage {
  position: relative;
  width: 100%;
  height: 100%;
  margin: rem(10px) 0;
  z-index: 2;
  overflow: hidden;
  border-radius: rem(25px);
  transition: 0.3s;
  min-height: rem(220px);
  min-width: rem(220px);
  padding: rem(6px);
  background: #1e1c27;
  box-shadow: 0 0 rem(42px) 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(22.5px);
  cursor: pointer;

  &:hover {
    .content {
      border-color: #fff;
    }
  }

  .content {
    display: flex;
    padding: rem(12px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(12px);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    border: 1px dashed #53525d;

    .tip {
      text-align: center;
      display: flex;
      align-items: center;
      gap: rem(12px);
      justify-content: center;
      flex-direction: column;
    }
  }

  .deleteImageContainer {
    position: absolute;
    right: 8%;
    top: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(5px);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    cursor: pointer;
    transition: 0.5s ease;

    .deleteImage {
      fill: white;
    }
  }

  @media (hover: hover) {
    &:hover {
      .deleteImageContainer {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  @media (hover: none) {
    .deleteImageContainer {
      opacity: 1;
      visibility: visible;
    }
  }

  .imageWrapper {
    z-index: 3;
    display: flex;
    padding: rem(6px);
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;

    &.active {
      background: linear-gradient(160deg, #9e5992, #6a19ab, #3054bb);
    }
    .image {
      border-radius: rem(26px);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    color: #fff;
    text-align: center;
    font-size: rem(14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .inputLabel {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
    z-index: 4;
    cursor: pointer;
  }
}
