@import '~styles/mixins';

.container {
  width: 100%;
  padding: 0 rem(16px);
  display: flex;
  align-items: start;
  overflow-x: scroll;
  position: relative;
  gap: rem(12px);
  margin-bottom: rem(32px);

  .buttonWrapper{
    display: flex;
    height: rem(45px);
    padding: rem(14px) rem(16px);
    justify-content: center;
    align-items: center;
    gap: rem(8px);
    border-radius: rem(102px);

    &Text{
      color: var(--White, #FFF);
      font-size: rem(18px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
    }
  }
  .active {
    background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
  }
  .unActive{
    background: #1E1C27;
  }

}