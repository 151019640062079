@import '~styles/mixins';

.content {
  border-radius: rem(26px);
  border: 2px solid #1E1C27;
  background: #13111C;
  box-shadow: 0 0 rem(32px) 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  padding: rem(22px);
  flex-direction: column;
}
