@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: rem(10px) rem(18px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: rem(2.5px) solid #1E1C26;
  transition: background 50ms linear;
  user-select: none;

  &:hover {
    background: #282631;
  }

  .iconSetting {
    width: rem(20px);
    height: rem(20px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userInfo {
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-right: rem(10px);

    &__Wrapper {
      position: relative;
    }

    &__Avatar {
      border-radius: rem(200px);
      width: rem(42px);
      height: rem(42px);
    }
  }

  .userContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(5px);
    &__Actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
     .actionButton {
       border-radius: 100px;
       background: linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
       padding: 5px 14px;
       gap: 6px;
       color: #FFF;
       font-style: normal;
       font-weight: 500;
       line-height: normal;
     }
    }

    .userMessageWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    &__Nickname {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: rem(10px);
      transition: color 50ms linear;
    }
  }

}
