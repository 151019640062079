@import '~styles/mixins';

.main {
  height: 100%;
  flex-grow: 1;
  padding-bottom: var(--bottom-padding);
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.errorMessage {
  text-align: center;
}

.errorLink {
  margin-top: rem(25px);
  margin-left: auto;
  margin-right: auto;
  max-width: rem(350px);
}

.cardsContainer {
  display: flex;
  gap: rem(12px);
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: rem(13px);
  }
}

.secondColumn {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: rem(12px);
  align-items: start;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(12px);
}
