@import '../../styles/_mixins.scss';

.variationFinishPage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
}
