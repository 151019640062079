@import '~styles/mixins';

.comments {
  display: flex;
  flex-direction: column;
  gap: rem(16px);
}

.content {
  display: flex;
  flex-direction: column;
}
