@import '~styles/mixins';

.container {
  position: absolute;
  bottom: 0;
  right: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(22px);
  width: 400px;
  padding: rem(29px) rem(30px);
  background: #13111c;
  border: 2px solid #1f1d28;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  z-index: -1;

  .title {
    font-weight: 500;
    font-size: rem(17px);
  }

  .video {
    width: rem(250px);
    height: rem(250px);
    border-radius: rem(17px);
  }

  .text {
    font-weight: 500;
    font-size: rem(15px);
    text-align: center;
    color: #d1d1d6;
  }

  .button {
    padding: rem(17px) rem(86px);
  }
}
