@import '~styles/mixins';

.pluginsPage {
  padding-bottom: rem(60px);
  width: 100%;


  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    .loaderWrapper {
      width: 100%;
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;

      .loader {
        width: rem(40px);
        height: rem(40px);
      }
    }

    .plugins {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      gap: rem(20px);

      @media (max-width: rem(1650px)) {
        align-self: center;
      }
      @media (max-width: rem(1150px)) {
        width: 100%;
        align-self: center;
      }

      .tagsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10px);
        padding: rem(10px) 0px;

        @media (max-width: rem(1150)) {
          padding: rem(16px) rem(20px);
          justify-content: center;
        }
      }

      .sortContainer {
        display: flex;
        align-items: center;
        gap: rem(12px);
        align-self: flex-end;

        @media (max-width: rem(1150px)) {
          align-self: center;
        }

        .sortTitle {
          font-size: rem(15px);
          color: #d1d1d6;
        }

        .sortItem {
          position: relative;

          input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
          }

          span {
            display: flex;
            align-items: center;
            gap: rem(6px);
            padding: rem(13px) rem(22px);
            background: #1e1c27;
            border-radius: 100px;
            transition: all 1s ease;
          }

          input:checked + span {
            background: linear-gradient(
              116deg,
              #9e5992 3.98%,
              #6a19ab 58.61%,
              #3054bb 112.12%
            );
          }
        }
      }

      .postsContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: rem(20px);

        @media (max-width: rem(1620px)) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: rem(1150px)) {
          padding: 0px rem(25px);
        }
        @media (max-width: rem(624px)) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
