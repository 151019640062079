@import '~styles/mixins';

.postPage {
  width: 100%;
  background: #13111c;
  padding-top: rem(40px);
  //   height: 100%;

  .goBackButton {
    width: rem(50px);
    height: rem(50px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: rem(20px);
  }

  .content {
    display: flex;
    justify-content: center;
    height: 100%;

    .postWrapper {
      width: 100%;
      height: 100%;
      max-width: rem(680px);
      background: #13111c;
      .postImage {
        max-height: unset;
      }

      .postItem {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.recommendations__swiper {
  margin-top: 25px;
  width: 100%;

  .recommendations__swiperItem {
    position: relative;
    max-width: 300px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: rem(26px);
      user-select: none;
      pointer-events: none;
    }

    @media (max-width: rem(550px)) {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

.comment {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: rem(20px);
  width: 100vw;
  margin: rem(25px) 0 rem(20px) 0;
  position: sticky;
  top: 0;
  padding: rem(20px) rem(10px);
  background: #13111c;
  z-index: 100;
  padding-bottom: rem(22px);
  @media (max-width: 640px) {
    padding: rem(10px) rem(20px);
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__Heading {
    user-select: none;
    color: #fff;
    font-size: rem(26px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 800px) {
      font-size: rem(22px);
    }
    @media (max-width: 420px) {
      font-size: rem(15px);
    }
  }

  &__Button {
    display: flex;
    white-space: nowrap;
    height: rem(42px);
    padding: 0 rem(22px);
    justify-content: center;
    align-items: center;
    gap: rem(8px);
    border-radius: rem(202px);
    background: linear-gradient(
      116deg,
      #9e5992 3.98%,
      #6a19ab 58.61%,
      #3054bb 112.12%
    );

    @media (max-width: 380px) {
      padding: 0 rem(9px);
    }
  }
  &__Text {
    color: #fff;
    font-size: rem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 800px) {
      font-size: rem(13px);
    }
  }
}

.link {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.comments {
  // max-width: rem(300px)
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: rem(20px);
}

.fullScreenContainer {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s ease,
    visibility 0.5s;
  z-index: 99;
  display: flex;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(50px);
  margin: rem(15px);
  border-radius: 20px;
  padding: rem(8px);
  top: 0;
  right: 0;
  transition: background 50ms linear;

  @media (max-width: 650px) {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  .iconSetting {
    display: flex;
    align-items: center;
  }
}

.recommendations__swiperItem {
  position: relative;
}

.recommendations__swiperItem:hover .fullScreenContainer {
  opacity: 1;
  visibility: visible;
}
