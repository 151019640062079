@import '~styles/mixins';

.container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: rem(11px) 0 rem(11px) rem(11px);
  border-radius: rem(200px);
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #282631;
  }

  .text {
    margin: 0 rem(8px);
    font-size: rem(16px);
    color: #ffffff;
  }
}
