@import '~styles/mixins';

.aiChatPage {
  width: 100%;
  height: calc(var(--height) - var(--content-top) - rem(32px));

  @media (max-width: 1150px) {
    height: calc(var(--height) - var(--content-top));
  }
  @media (max-width: 640px) {
    margin-bottom: -110px;
  }

  .chatMessagesContainer {
    width: 100%;
    height: 100%;
    @media (max-width: 950px) {
      width: 100%;
    }
  }

  .chatsContainer {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: rem(325px);

    @media (max-width: 950px) {
      max-width: 100%;
    }
  }

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    border-radius: rem(24px);
    border: 2px solid #1e1c26;
    @media (max-width: 640px) {
      border: 0;
      border-radius: 0;
    }
  }
}
