.Toastify__progress-bar--error {
  background: linear-gradient(
    115.93deg,
    #9e5959 3.98%,
    #ab1919 58.05%,
    #bb3030 112.12%
  ) !important;
}
.Toastify__progress-bar--success {
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%) !important;
}
