@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  position: sticky;
  .content {
    width: rem(42px);
    height: rem(42px);
    position: absolute;
    right: 0;
    bottom: 0;
    .contentButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: rem(202px);
      background: #1E1C27;
      box-shadow: 0 rem(6px) rem(22px) 0 rgba(0, 0, 0, 0.25);
    }
  }
}
