@import '~styles/mixins';

.delete {
  display: flex;
  flex-direction: column;
  gap: rem(12px);

  &Title {
    width: 100%;
    margin-bottom: rem(22px);
    color: rgba(255, 255, 255, 0.7);
    font-size: rem(18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: rem(6px);

    .title {
      font-size: rem(18px);
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: rem(5px);
    }
  }

  .button {
    margin-top: rem(22px);
    max-width: 200px;
    width: 100%;
    font-size: rem(18px);
    font-weight: 600;
  }
}
