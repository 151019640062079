@import '~styles/mixins';

.wrapper {
  user-select: none;
  display: flex;
  cursor: pointer !important;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(17px);
  min-height: 200px;
  height: 100%;
  min-height: rem(300px);
  width: 100%;
  padding: rem(15px);
  background: #24222f;
  border-radius: rem(24px);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  animation-name: appearing;
  animation-duration: 2s;

  .conceptFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    height: rem(50px);

    .checkbox {
      // Убрать при наличии массового удаления альбомов
      opacity: 0;
      pointer-events: none;
      //

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: flex;
      width: rem(27px);
      height: rem(27px);
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 6px;
      border: rem(3px) solid #383744;
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      &:checked {
        border: rem(3px) solid #fff;
        background-image: url('../icons/CheckboxTickIcon.svg');
        box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      }
    }

    .conceptTitle {
      font-size: rem(18px);
      font-weight: 500;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15ch;
      @media (max-width: 600px) {
        font-size: rem(15px);
      }
    }

    @media (max-width: 350px) {
      font-size: rem(15px);
    }

    .actions {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: rgba(235, 235, 245, 0.05);
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      display: flex;
      height: rem(42px);
      padding: 0 rem(12px);
      justify-content: center;
      align-items: center;
      transition: background 50ms linear;

      &:hover {
        background: #282631;
      }
    }
  }

  @media (max-width: rem(600px)) {
    border-radius: rem(15px);
  }

  @keyframes appearing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .image {
    width: 100%;
    border-radius: rem(25px);
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .noImage {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: rem(25px);
    object-fit: cover;
  }

  &[data-favorite='true'] {
    .conceptFooter {
      justify-content: center;
    }
    .checkbox,
    .actions {
      display: none !important;
      pointer-events: none;
    }
  }
}

.imgWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
