@import '~styles/mixins';

.processing {
  // position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: rem(1100px)) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: rem(20px);
  }
}

.processingStage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: rem(40px);
}

.loaderWrapper {
  position: relative;
  width: rem(300px);
  height: rem(300px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: 'rgba(30, 28, 43, 0.50)';

  @media (max-width: rem(841px)) {
    width: rem(250px);
    height: rem(250px);
  }
}

.bannerText {
  color: #fff;
  text-align: center;
  font-size: rem(22px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.logo {
  position: absolute;
  width: rem(120px);

  // height: rem(80px);
  .logoImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.percentageText {
  position: absolute;
  bottom: 20%;
  font-size: rem(18px);
  color: 'white';
}

.longLoading {
  position: absolute;
  top: rem(20px);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translateX(-50%);
  width: max-content;
  z-index: 10;

  @media (max-width: 1100px) {
    justify-content: center;
    position: absolute;
    top: rem(10px);
  }

  @media (max-width: 600px) {
    min-width: rem(280px);
    top: rem(40px);
  }

  .longLoadingWrapper {
    max-width: rem(350px);
    position: relative;
    width: 100%;
    border-radius: rem(15px);
    align-self: flex-start;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
      border-radius: rem(15px);
      padding: rem(5px);
    }

    .longLoadingText {
      padding: rem(10px) rem(30px) rem(10px) rem(10px);
      background-color: hsla(251, 27%, 11%, 1);
      border-radius: rem(15px);
      position: relative;
      z-index: 1;
      font-size: rem(15px);
    }
  }
  .closeIcon {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.text {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: rem(18px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
