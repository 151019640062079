.container {
  width: 100%;
  height: calc(var(--height, 100%) - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chatTextBox {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 83%;
  overflow-y: auto;
}

.chatInput {
  flex-grow: 0;
  flex-shrink: 0;
}
