@import '~styles/mixins';

.postContainer {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
  width: 100%;
  background-color: #191724;
  padding: rem(16px) rem(22px);
  border-radius: rem(14px);
  transition: all 0.3s ease;

  &[data-type-plugin='true'] {
    max-width: rem(640px);
    @media (max-width: 640px) {
      background-color: transparent;
    }
  }
  &:hover[data-type-plugin='false'] {
    cursor: pointer;
    background-color: #1e1c27;
  }

  .postHeader {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(12px);
    flex-grow: 1;
    .avatar {
      width: rem(44px);
      height: rem(44px);
      object-fit: cover;
      border-radius: 50%;
    }
    .postTitle {
      color: #fff;
      font-size: rem(16px);
      font-weight: 500;
    }
    .postClose {
      position: absolute;
      top: rem(11);
      right: 0;
      width: rem(20px);
      height: rem(20px);
      @media (max-width: 640px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .postText {
    font-size: rem(15px);
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    min-height: rem(50px);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    &[data-type='true'] {
      display: block;
    }
  }
  .postCategory {
    position: relative;
    width: max-content;
    padding: rem(7px) rem(13px);
    font-size: rem(13px);
    border-radius: rem(15px);
    border: 1px solid transparent;
    background:
      linear-gradient(#282631, #282631) padding-box,
      linear-gradient(116deg, #9e5992 3.98%, #6a19ab 58.61%, #3054bb 112.12%)
        border-box;
  }
  .postFoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(10px);
    width: 100%;
    .button {
      padding: 0;
    }
  }
  .postLikes {
    display: flex;
    align-items: center;
    gap: rem(7px);
    padding: rem(10px);
    font-size: rem(13px);
    border-radius: 20px;
    transition: all 0.2s ease;

    svg {
      width: rem(22px);
      height: rem(22px);
    }
    &:hover {
      background-color: #282631;
    }
  }
  .postLink {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding: rem(8px) rem(22px);
    font-size: rem(15px);
    border-radius: rem(100px);
    background: linear-gradient(
      116deg,
      #9e5992 3.98%,
      #6a19ab 58.61%,
      #3054bb 112.12%
    );
  }
}
