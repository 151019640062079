@import '~styles/mixins';

.wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: rem(26px);
    .title{
        font-size: rem(26px);
        font-weight: 700;
    }
    .subtitle{
        font-size: rem(18px);
        max-width: rem(335px);
        width: 100%;
        text-align: center;
    }
    .link{
        box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
        background: linear-gradient(90deg, #9e5992 0%, #6a19ab 50.52%, #3054bb 100%);
        border-radius: rem(200px);
        max-width: rem(270px);
        width: 100%;
        padding: rem(17px) 0;
        text-align: center;
    }
}
