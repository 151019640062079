@import '~styles/mixins';

.heading {
    color: rgba(235, 235, 245, 0.30);
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}