@import '~styles/mixins';

.wrapper {
  height: 100%;
  margin: rem(20px) 0 0 0;
  animation-name: appearing;
  animation-duration: 2s;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(22px);
    padding: rem(15px);
    margin-top: rem(20px);
    height: 100%;
    min-height: rem(390px);
    border-radius: rem(24px);
    background: #191724;

    .title {
      font-size: rem(18px);
      text-align: center;
    }

    .description {
      color: #948aa3;
      text-align: center;
    }
  }
}
@keyframes appearing {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
