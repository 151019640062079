@import '~styles/mixins';

.suggestionsWrapper {
    width: 100%;
  }

  .suggestionsList {
    padding-top: rem(20px);
    display: flex;
    flex-direction: column;
    gap: rem(15px);
    list-style: none;
    

    .suggestion {
      flex-shrink: 0;
      background: #191724;
      position: relative;
      border-radius: rem(12px);
      border: 1px solid #1E1C27;
      padding: rem(16px);
      box-shadow: 0 0 rem(32px) 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        background: #1E1C27;
      }

      .text {
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        font-size: rem(14px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        z-index: 2;
      }

    }
  }
