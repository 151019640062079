@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: 0 rem(20px);
  position: sticky;
  border-top-left-radius: rem(20px);
  background-color: #191724;
  top: 0;
  z-index: 4;
  border-bottom: 1px solid #1E1C26;

  @media(max-width: 950px){
    border-top-right-radius: rem(20px);
  }

  @media(max-width: 640px) {
    border-radius: 0;
  }

  .iconSetting {
    transition: transform 200ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconSettingActive{
    transform: rotate(45deg);
  }

  .content {
    display: flex;
    height: rem(62px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    &__Icon {
      transition: all 200ms ease-in-out;
      border-radius: rem(200px);
      padding: rem(6px) ;
      &:hover {
        background: #282631;
      }
    }
  }
}