.fromUserAvatar {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .imageWrapper {
    width: 40px;
    height: 40px;
    img {
      border-radius: 200px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .unViewed {
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid #13111c;
    background: linear-gradient(94.62deg, #c400d6 7.3%, #4628ff 99.05%);
  }
}

.link {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
