@import '~styles/mixins';

.feedPage {
  width: 100%;

  .wrapper {
    height: 100%;
    width: 100%;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(var(--height) - var(--content-top) - rem(32px));
    display: flex;
    flex-direction: column;
    align-items: center;

    .feed {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .switch {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }
}
