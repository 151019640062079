@import '~styles/mixins';

.container {
  width: 100%;
  padding-bottom: rem(12px);

  .content {
    display: grid;
    grid-template-columns: auto rem(400px);
    gap: rem(32px);
    height: 100%;
    width: 100%;
    align-items: start;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 14px;
      grid-template-columns: 100%;
      grid-template-areas: 'aside' 'main';
    }
  }
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: rem(90px);
  background: #13111c;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  border: 2px solid #1f1d28;
  padding: rem(12px);
  min-height: calc(var(--height) - var(--content-top) - 2rem);

  @media (max-width: 768px) {
    grid-area: main;
    min-height: rem(300px);
    position: relative;
  }
}

.aside {
  margin-bottom: rem(32px);

  @media (max-width: 768px) {
    grid-area: aside;
    background: #13111c;
  }
}

.wrapper {
  min-height: calc(var(--height) - var(--content-top) - 2rem - (rem(12px) * 2));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    min-height: fit-content;
  }

  &.loaderWrapper {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.dalleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.visible {
  display: none;
}

.load {
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
}
