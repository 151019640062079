@import '~styles/mixins';

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    .search__icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width:380px){
            width: 20px;
            height: 20px;
        }
    }
}