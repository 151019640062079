.codeBlockWrapper {
  width: 100%;
  max-width: 500px;
  overflow-x: scroll;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  word-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  overflow-wrap: inherit;
  white-space: pre-wrap;
  word-break: inherit;
  margin: 0;

  @media (max-width: 650px) {
    max-width: 350px;
  }

  @media (max-width: 500px) {
    max-width: 300px;
  }

  @media (max-width: 450px) {
    max-width: 250px;
  }
  @media (max-width: 400px) {
    max-width: 200px;
  }

  code , pre {
    color: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    word-wrap: inherit;
    overflow-x: scroll;
    width: 100%;
    justify-content: inherit;
    align-items: inherit;
    overflow-wrap: inherit;
    white-space: pre-wrap;
    word-break: inherit;
    margin: 0;
    font-family: Montserrat;
  }

  pre {
    width: 100%;
    div{
      width: 100%;
    }
  }
}