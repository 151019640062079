@import '~styles/mixins';

.generationFinishPage {
  flex-grow: 1;
  // padding-bottom: var(--bottom-padding);
  height: 100%;
}

.container{
  max-width: rem(1360px);
  height: 100%;
  margin: 0 auto;
}