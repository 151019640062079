.sr-only {
  display: none;
}

.techWorkModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #13111c;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 90%;
    max-width: 700px;
    min-height: 200px;
    padding: 20px 20px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #fff;

    .techWorkModal__subtext {
      font-size: 20px;
    }
  }
}
