@import '~styles/mixins';

.label {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-size: rem(20px);
  font-weight: 600;
  line-height: rem(24px);
  border-radius: rem(15px);
  border-bottom: rem(1px) solid transparent;
  padding: rem(15px) rem(25px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  &WithIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: rem(10px);
  }
  @media (max-width: rem(767px)) {
    font-size: rem(15px);
    line-height: rem(18px);
  }
}
