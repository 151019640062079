@import '~styles/mixins';

.language {
  display: flex;
  flex-direction: column;
  gap: rem(14px);
  &Item {
  }
}

.label {
  cursor: pointer;
  width: 100%;
  padding: rem(15px) rem(25px);
  display: flex;
  align-items: center;
  gap: rem(15px);
  color: rgba(#ffffff, 0.75);
  font-size: rem(15px);
  font-weight: 500;
  line-height: rem(24px);
  transition: background-color 0.3s ease;
  background: #191724;
  &:hover {
    background: #282631;
  }
}

.checkIcon {
  fill: currentColor;
  opacity: 0.3;
  margin-left: auto;
}

.list {
  list-style: none;
  border-radius: rem(15px);
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  width: 100%;

  :first-child {
    .label {
      border-radius: rem(14px) rem(14px) 0 0;
    }
  }

  :last-child {
    .label {
      border-radius: 0 0 rem(14px) rem(14px);
    }
  }

  &Title {
    color: rgba(255, 255, 255, 0.7);
    font-size: rem(18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
  }
  &Item {
    align-self: stretch;
    gap: rem(17px);
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
  }
}
