.whatExactly {
  margin-left: auto;
  position: relative;
  white-space: nowrap;

  .unFollow , .follow{
    transition: background 50ms linear;
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
  }

  .unFollow {
    background: #1E1C27;
    &:hover {
      background: #282631;
    }
  }

  .follow {
    background: linear-gradient(116deg, #9E5992 0%, #6A19AB 50%, #3054BB 100%);
  }


  .imageWrapper {
    width: 50px;
    height: 50px;
    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image {
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.link {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
