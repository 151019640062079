@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: rem(10px) rem(18px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: rem(2.5px) solid #1E1C26;
  transition: background 50ms linear;
  user-select: none;

  &:hover {
    background: #282631;
  }

  .iconSetting {
    width: rem(20px);
    height: rem(20px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userInfo {
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-right: rem(10px);

    &__Wrapper {
      position: relative;
    }

    &__Avatar {
      border-radius: rem(200px);
      width: rem(42px);
      height: rem(42px);
    }
  }

  .userTextContainer {
    width: 100%;
    .userMessageWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .chatInfo {
        gap: rem(12px);
        display: flex;
        align-items: center;
        flex-direction: row;

        &__Time {
          color: #93919B;
          font-size: rem(12px);
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        &__Inbox {
          display: flex;
          padding: rem(4px) rem(7px);
          flex-direction: column;
          align-items: center;
          border-radius: 200px;
          background: #13111C;
        }
      }
    }

    &__Nickname {
      display: flex;
      align-items: center;
      gap: rem(10px);
      transition: color 50ms linear;

    }

    &__LastMessage {
      margin-top: rem(8px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      color: #B0B0B8;
      text-overflow: ellipsis;
      font-size: rem(14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      align-self: flex-start;
      text-align: left;
    }
  }

}

.inboxBackground{
  background: #272437;
}

.activeBackground{
  background: #1E1C26;
}

.lastContainerItem{
  border: 0
}