@import '~styles/mixins';

.notification {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &Text {
    width: 70%;
    font-size: rem(22px);
    font-weight: 500;
    text-align: center;
    color: #948aa3;
  }
}
