@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: rem(992px)) {
    max-width: rem(1442px);
  }
  @media (max-width: 500px) {
    max-width: 100%;
  }
  &Fluid {
    @media (min-width: rem(992px)) {
      // max-width: 100%;
    }
  }
  &FullHeight {
    height: 100%;
    min-height: calc(var(--height, 100vh) - rem(100px));
  }

  &NoPaddingOnMobile{
    @media (max-width: rem(400px)) {
      padding-right: 0;
      padding-left: rem(8px);
    }
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}
