.document {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    user-select: auto;
  }
  h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  a {
    text-decoration: underline;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: var(--bottom-height);
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    min-height: var(--bottom-height);
  }
  @media (max-width: 575px) {
    margin-left: 0;
    margin-right: 0;
  }
}
