@import '~styles/mixins';

.wrapperList {
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  width: rem(600px);
  padding: rem(16px);
  background: #191724;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: rem(26px);

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -30px;
    width: rem(40px);
    height: 52px;
  }

  .listItem {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    width: 100%;
    padding: rem(16px);
    border-radius: rem(22px);
    overflow: hidden;
    transition: background 0.3s;

    &:hover {
      background: #282631;
    }

    .header {
      display: flex;
      align-items: center;
      gap: rem(12px);

      .image {
        width: rem(26px);
        height: rem(26px);
      }
      .title {
        font-weight: 600;
        font-size: rem(18px);
      }
    }

    .text {
      line-height: 140%;
      color: #d1d1d6;
    }

    .link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: rem(22px);
      z-index: 1;
    }
  }
}
