@import '~styles/mixins';

.finishStage {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: rem(20px);
}

.content {
  display: grid;
  // flex-direction: row;
  grid-template-columns: 1fr ;
  align-items: start;
  gap: rem(40px);
  position: relative;
  width: 100%;
  height: 100%;


}

.controls {
  margin-top: rem(20px);
  flex-wrap: nowrap;
  @media (max-width: rem(767px)) {
    margin-top: rem(15px);
  }
  @media (max-width: rem(400px)) {
    flex-wrap: wrap;
  }

  &Button {
    width: 100%;
    max-width: rem(350px);

    @media (max-width: rem(1130px)) {
      padding: rem(13px);
      max-width: rem(300px);
      & > span {
        font-size: rem(16px);
      }
    }
  }
}
