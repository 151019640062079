
@import '~styles/mixins';

.heading {
  color: rgba(235, 235, 245, 0.30);
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.powerSelection {
    display: flex;
    flex-direction: column;
    gap: rem(20px);

    .rangeWrapper {
      position: relative;
      @media (max-width: 840px) {
          margin: 0px 10px;
      }
      .line {
        position: absolute;
        height: 100%;
        width: 2px;
        z-index: 1;
        background-color: #2E2C37;
        top: 0;
      }

    }
  }