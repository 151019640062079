@import '~styles/mixins';


.powerSelection {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .heading {
    color: rgba(235, 235, 245, 0.30);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .resolutionContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(12px);
    width: 100%;
    .resolutionButton{
      display: flex;
      padding: rem(16px) rem(14px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(12px);
      background: #191724;
      border-radius: rem(14px);
      flex: 1 0 0;
    }
    .isActive{
      background:linear-gradient(116deg, #9E5992 3.98%, #6A19AB 58.61%, #3054BB 112.12%);
    }
  }
}
