@import '~styles/mixins';

.subs__item {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    .sub__avatar {
      position: relative;
      min-width: 50px;
      height: 50px;
      img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      display: flex;
      align-items: center;
      gap:rem(10px);
      .sub__username {
          position: relative;
          font-weight: 400;
          font-size: rem(15px);
          line-height: rem(18px);
          @media (max-width: 420px) {
            font-size: rem(13px);
            line-height: rem(16px);
          }
      }
    }
    .followOrUnfollow {
        margin-left: auto;
    }
}

.link {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}