@import '~styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(19, 17, 28, 0.5);
  backdrop-filter: blur(rem(10px));
  display: none;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &Open {
    display: block;
    pointer-events: auto;
  }

  padding: rem(20px);
}

.modalContainer {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
}

.modal {
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    border-radius: rem(10px);
    top: rem(-1px);
    left: rem(-1px);
    width: calc(100% + rem(2px));
    height: calc(100% + rem(2px));
    background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  }
}

.content {
  border-radius: rem(10px);
  position: relative;
  width: 100%;
  max-height: 95vh;
  outline: none;
  background-color: var(--background-color);
  padding: rem(20px);
  overflow: auto;
}

.head {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: rem(10px);
}

.heading {
  font-size: rem(15px);
  line-height: rem(20px);
  font-weight: 600;
}