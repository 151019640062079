@import '~styles/mixins';

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: rem(10px);
  flex-direction: column;
  margin-bottom: rem(10px);
  .privateSubsContent {
      display: flex;
      justify-content: center;
      align-items: center;
  
      @media (max-width: rem(767px)) {
        font-size: rem(15px);
        line-height: rem(18px);
      }
  
      @media (max-width: rem(450px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
  }
  .privatePostsInfo {
      text-align: center;
      color: grey;
      font-size: rem(11px);
      line-height: rem(14px);
  }
}