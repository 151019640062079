@import '~styles/mixins';

.containerModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  width: 100%;
  transition: opacity 300ms, transform 300ms;
  transform: scale(0.95);
  visibility: hidden;
  height: 100%;
  background: rgba(19, 17, 28, 0.40);
  backdrop-filter: blur(15px);
  z-index: 1000;
}

.modalContent {
  padding: 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 460px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #1F1D28;
  background: #13111C;
  text-align: center;
  @media(max-width: 640px){
    padding: 20px;
    margin: 0 20px;

  }
}

.modalTitle {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  line-height: normal;
}

.modalMessage {
  margin-bottom: 20px;
  font-size: 16px;
}

.modalActions {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  button {
    display: flex;
    width: 90px;
    height: 50px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: background 50ms linear;
    border-radius: 200px;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.10);
  }

  .confirmButton {
    background: #1E1C27;
    color: white;

    &:hover {
      background: #282631;
    }
  }

  .cancelButton {
    background: #A73939;
    background: darken(#A73939, 3%);
    color: white;

    &:hover {
      background: #A73939;
    }
  }

}

.containerModalOpen {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

.containerModalClose {
  opacity: 0;
  transform: scale(0.95);
  visibility: hidden;
}