@import '~styles/mixins';

.header {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #191724;
  border-top-right-radius: rem(20px);
  max-height: rem(63px);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: rem(13px) rem(20px);
  border-bottom: 1px solid #1e1c26;
  @media (max-width: 640px) {
    border: 0;
    border-radius: 0;
    padding: rem(13px) rem(10px);
  }
}

.buttonGoBack {
  display: flex;
  width: rem(42px);
  height: rem(42px);
  justify-content: center;
  align-items: center;
  gap: rem(12px);
  flex-shrink: 0;
  transition: background 200ms linear;
  border-radius: 200px;
  background: #282631;

  .arrowIconSetting {
    transform: rotate(90deg);
  }
}

.chatHeaderUser {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: rem(20px);
  position: relative;
  max-width: rem(400px);
  width: 100%;

  @media (max-width: rem(400px)) {
    gap: rem(10px);
  }
}

.avatarWrapper {
  overflow: hidden;
  width: rem(45px);
  height: rem(45px);
  border-radius: rem(100px);
  user-select: none;
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
  }

  @media (max-width: rem(400px)) {
    width: rem(40px);
    height: rem(40px);
    border-radius: rem(15px);
  }
}

.nicknameWrapper {
  display: flex;
  flex-direction: column;
  gap: rem(5px);
  padding-top: rem(4px);
}

.chatThemesSelect {
  flex: 1;
}

.nickname {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(24px);
  transition: 0.3s;

  @media (max-width: rem(400px)) {
    font-size: rem(15px);
    line-height: rem(18px);
  }
}

.date {
  font-weight: 400;
  font-size: rem(11px);
  line-height: rem(6px);
  color: hsla(240, 6%, 83%, 1);

  @media (max-width: rem(400px)) {
    font-size: rem(10px);
    line-height: rem(12px);
  }
}
