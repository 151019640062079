@import '~styles/mixins';

.notifications {
  position: relative;
  width: 24px;
  height: 24px;

  @media(max-width:380px){
    width: 20px;
    height: 20px;
  }
  

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .notif__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: white;
  }
  .hints {
    overflow: hidden;
    position: absolute;
    right: 0%;
    top: -3px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color:#FFFF;
  
    @media (max-width: rem(800px)) {
      right: -3px;
      top: -4px;
    }
    // background: linear-gradient(94.62deg, #c400d6 7.3%, #4628ff 99.05%);
    .notif__count {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 10px;
      transform: translate(-50%, -50%);
      color:#13111C;
    }
    .two {
      font-size: 9px;
    }
    .three {
      font-size: 8px;
    }
  }
}

.link {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
