@import '~styles/mixins';

.container {
  display: flex;
  gap: rem(22px);
  width: 70%;

  @media (max-width: 1150px) {
    width: 100%;
  }

  .textarea {
    flex: 1;
    height: auto;
    max-height: 200px;
    padding: rem(16px) rem(22px);
    color: #b0b0b8;
    font-size: rem(18px);
    font-weight: 400;
    font-family: 'Montserrat';
    background-color: #1e1c27;
    border-radius: rem(26px);
    outline: none;
    border: none;
    resize: none;

    @media(max-width: 768px) {
      min-height: rem(62px);
      border-radius: rem(15px);
    }

    &::placeholder {
      color: #b0b0b8;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    align-self: flex-end;
    padding: rem(15px);
    background: linear-gradient(
      116deg,
      #9e5992 3.98%,
      #6a19ab 58.61%,
      #3054bb 112.12%
    );
    border-radius: rem(200px);

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
