@import '~styles/mixins';

.main {
  width: 100%;
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  @media (max-width: rem(640px)) {
    padding: 0 14px
  }
}

.content{
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: rem(22px);
}