@import '~styles/mixins';
@import '~styles/variables';

ul {
  list-style-type: none;
}

.container {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 200px;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: #282631;
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    padding: rem(8px);
    border-radius: rem(200px);
  }
  .settingsWrapper {
    border-radius: rem(10px);
    position: absolute;
    display: flex;
    align-items: center;
    top: 140%;
    left: 50%;
    transform: translateX(-82%);
    z-index: 999;

    .settingsWindow {
      border-radius: rem(10px);
      min-width: rem(150px);
      background-color: #282631;
      position: relative;
      z-index: 999;
      text-wrap: nowrap;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 73%;
        border-left: rem(12px) solid transparent;
        border-right: rem(12px) solid transparent;
        border-bottom: rem(12px) solid #282631;
        width: 0;
        height: 0;
      }

      .actions {
        user-select: none;
        transition: background 50ms linear;
        border-radius: rem(10px);
        overflow: hidden;

        .action {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px 14px;
          font-weight: 500;
          font-size: rem(14px);
          color: #fff;
          transition: background 0.3s linear;
          cursor: pointer;

          &:hover {
            background: #1e1c27;
          }
        }

        .downloadAction {
          display: flex;
          align-items: center;
          color: #fff;
          width: 100%;
          cursor: pointer;
          padding: 10px 14px;
        }

        .deleteAction,
        .strikeAction {
          color: #ec4b5b;
        }
      }
    }
  }
}
