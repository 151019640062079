@import '~styles/mixins';

.deleteButton {
  position: fixed;
  left: 60%;
  transform: translateX(-50%);
  z-index:999;
  animation: slideUp 0.5s ease-out;
  border-radius: rem(200px);
  background: #13111C;
  box-shadow: 0 0 rem(42px) 0 rgba(0, 0, 0, 0.25);
  display: flex;
  padding: rem(14px);
  align-items: flex-start;
  gap: rem(12px);
  bottom: rem(22px);

  @media(max-width: 1161px){
    left: 50%;
    bottom: rem(102px)
  }
  @media(max-width: 840px){
    bottom: rem(120px)
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .chooseToDelete {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .massDeletion {
    display: flex;
    height: 50px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 200px;
    white-space: nowrap;
    background: #A83939;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

  }
}

.clear{
  display: flex;
  white-space: nowrap;
  height: 50px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 200px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #1F1D28;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.10);
}

@keyframes slideUp {
  0%{
    bottom: -100px;
  }
  50% {
    bottom: 0;
  }
}

@media(max-width: 1161px){
  @keyframes slideUp {
    0%{
      bottom: -100px;
    }
    50% {
      bottom: rem(102px)
    }
  }
}


.hidden{
  display: none;
}