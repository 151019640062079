@import '~styles/mixins';

.main {
  display: grid;
  grid-template-columns: auto rem(400px);
  gap: rem(32px);
  height: 100%;
  width: 100%;
  align-items: start;

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: rem(90px);
    padding-bottom: rem(40px);

    .loaderWrapper {
      min-height: calc(var(--height) - var(--content-top) - 2rem);
      padding: rem(12px);
      border: 2px solid #1F1D28;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;

      .processing {
        width: 100%;
        height: 100%;
      }
    }

    .wrapper {
      min-height: calc(var(--height) - var(--content-top) - 2rem);
      padding: rem(12px);
      border: 2px solid #1F1D28;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;

      .processing {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  .idleStageContainer {
    margin-bottom: rem(32px);
  }
}
