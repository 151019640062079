@import '~styles/mixins';
@import '~styles/variables';

ul {
  list-style-type: none;
}

.container {
  position: relative;
  z-index: 9999;
  cursor: pointer;

  .iconWrapper {
    display: flex;
    align-items: center;
    padding: rem(8px);
    border-radius: rem(200px);
    transition: all 200ms ease-in-out;

    &:hover {
      background-color: #282631;
    }
  }

  .settingsWrapper {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border-radius: rem(10px);
    position: absolute;
    display: flex;
    align-items: center;
    top: 140%;
    left: 50%;
    transform: translateX(-82%);
    z-index: 999;

    &.open {
      pointer-events: initial;
      cursor: pointer !important;
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
      display: block;
    }

    .settingsWindow {
      border-radius: rem(10px);
      min-width: rem(150px);
      width: fit-content;
      background-color: #282631;
      position: relative;
      z-index: 999;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        left: 73%;
        border-left: rem(12px) solid transparent;
        border-right: rem(12px) solid transparent;
        border-bottom: rem(12px) solid #282631;
        width: 0;
        height: 0;
      }

      .actions {
        font-size: rem(14px);
        font-weight: 600;
        user-select: none;
        transition: background 50ms linear;

        .deleteAction {
          display: flex;
          gap: rem(8px);
          align-items: center;
          color: #EC4B5B;
          width: 100%;
          cursor: pointer;
          padding: rem(12px) rem(16px);
          border-radius: rem(10px);
          //border-bottom: 1px solid #323240;

          &:hover {
            background: #1E1C27;
          }
        }

        .pinAction {
          display: flex;
          gap: rem(8px);
          align-items: center;
          width: 100%;
          cursor: pointer;
          padding: rem(12px) rem(16px);
          border-bottom: 1px solid #323240;

          &:hover {
            background: #1E1C27;
          }
        }

        .blockAction {
          display: flex;
          gap: rem(8px);
          align-items: center;
          width: 100%;
          cursor: pointer;
          padding: rem(12px) rem(16px);
          border-radius: 0 0 rem(10px) rem(10px);

          &:hover {
            background: #1E1C27;
          }
        }

        .hideAction {
          width: 100%;
          border-top: rem(2px) solid #323240;
          display: flex;
          align-items: center;
          color: #D1D1D6;
          cursor: pointer;
          padding: 10px 14px;
          border-radius: 0 0 rem(10px) rem(10px);

          &:hover {
            background: #1E1C27;
          }
        }
      }
    }
  }
}
