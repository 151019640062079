@import '~styles/mixins';

.socials {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  &Button {
    border-radius: 200px;
    background: #1e1c27;
    box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
    transition: background 50ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(42px);
    padding: 0 rem(14px);
    gap: rem(8px);
    &:hover {
      background: #282631;
    }
    span {
      color: #ffffff;
      font-size: rem(17px);
      font-weight: 400;
      line-height: rem(18px);
      margin-left: auto;
      margin-right: auto;
    }
    &:disabled {
      &:hover {
        background: #1e1c27;
      }
    }
  }
}
