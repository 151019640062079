@import '~styles/mixins';

.container {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__PaidSubscription {
      max-width: rem(350px);
      padding: rem(16px) rem(22px);
      gap: rem(10px);
      border-radius: rem(26px);
      background: #191724;
      box-shadow: 0 rem(6px) rem(22px) 0 rgba(0, 0, 0, 0.25);
    }

    &__UnPaidSubscription {
      gap: rem(12px);
    }

    &__PaidSubscription,
    &__UnPaidSubscription {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__Heading {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &__Note {
      color: #948aa3;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__PremuimContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(8px);
    }

    &__TextContainer {
      display: flex;
      flex-direction: column;
      gap: rem(12px);
      margin-bottom: rem(22px);
    }

    &__textPremium {
      color: #a74bff;
      font-size: rem(18px);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__Integration {
      display: flex;
      gap: rem(22px);
      flex-direction: row;

      &__Item {
        display: flex;
        align-items: center;
        gap: rem(8px);
        cursor: pointer;
        flex-direction: row;
        height: rem(52px);
        padding: 0 rem(22px);
        justify-content: center;
        border-radius: 200px;
        background: #1e1c26;
        box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
        transition: background 50ms linear;

        &:hover {
          background: #282631;
        }
      }
    }
  }

  .iconSetting {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
