@import '~styles/mixins';

.userContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: rem(12px) rem(14px);
  border-radius: rem(14px);
  background: #191724;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #1e1c27;
  }

  .column {
    display: flex;
    align-items: center;
    gap: rem(9px);

    .avatar {
      display: block;
      width: rem(42px);
      height: rem(42px);
      margin-right: rem(3px);
      object-fit: cover;
      border-radius: 50%;
    }

    .nickname {
      font-size: rem(18px);
      font-weight: 500;
    }

    .button {
      height: 40px;
      padding: 0 rem(22px);
      border-radius: rem(100px);
      background: linear-gradient(
        116deg,
        #9e5992 3.98%,
        #6a19ab 58.61%,
        #3054bb 112.12%
      );
    }

    .button.isFollow {
      border: 2px solid transparent;
      background:
        linear-gradient(#1e1c27, #1e1c27) padding-box,
        linear-gradient(116deg, #9e5992 3.98%, #6a19ab 58.61%, #3054bb 112.12%)
          border-box;
    }

    .chatButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(42px);
      height: rem(42px);
      padding: rem(9px);
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        border-radius: 200px;
        background: #282631;
      }

      svg {
        width: rem(26px);
        height: rem(26px);
      }
    }
  }
}
