@import '~styles/mixins';

.heading {
  color:  #FFF;
  font-size: rem(18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.photoSelection {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: rem(12px);
  overflow-x: auto;



  .historyListWrapper {
    width: 100%;
    height: 100%;
    margin: rem(10px) 0;

    .historyList {
      user-select: none;
      display: flex;
      flex-direction: row;
      height: 100%;
      gap: rem(15px);


      .wrapper {
        margin: rem(10px) 0;
        z-index: 2;
        background: #1E1C27;
        position: relative;
        overflow: hidden;
        border-radius: rem(25px);
        transition: 0.3s;
        cursor: pointer;
        min-height: rem(220px);
        min-width: rem(220px);
        width: 100%;


        &.active {
          background: linear-gradient(160deg , #9E5992 , #6A19AB , #3054BB );
        }


        img {
          padding: rem(6px);
          border-radius: rem(26px);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .link {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}