@import '~styles/mixins';

.assetContainer {
  display: flex;
  position: relative;
  border-radius: rem(26px);
  padding: rem(16px);
  gap: rem(16px);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: #191724;
  height: 100%;
  width: 100%;
  transition: background 200ms linear;
  animation-name: appearing;
  animation-duration: 2s;

  @keyframes appearing {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.fullScreen {
    z-index: 1000;
  }

  @media (max-width: rem(840px)) {
    border-radius: 0;
  }

  .selectWrapperButton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
  .assetImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: rem(26px);

    .link {
      display: flex;
      justify-content: center;
      width: inherit;
      height: inherit;
    }
  }

  .assetImage {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: rem(26px);
    object-fit: cover;
  }

  .assetActions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    gap: rem(12px);
    user-select: none;

    .shareAction {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      z-index: 2;
      background: linear-gradient(
        116deg,
        #9e5992 3.98%,
        #6a19ab 58.61%,
        #3054bb 112.12%
      );
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      display: flex;
      height: rem(42px);
      padding: 0 rem(16px);
      justify-content: center;
      align-items: center;
      gap: rem(8px);

      &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .actions {
      color: #fff;
      text-align: center;
      font-size: rem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 200px;
      background: rgba(235, 235, 245, 0.05);
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
      display: flex;
      height: rem(42px);
      padding: 0 rem(12px);
      justify-content: center;
      align-items: center;
      transition: background 50ms linear;
      z-index: 2;
      &:hover {
        background: #282631;
      }
    }
  }

  .fullScreenContainer {
    transition:
      opacity 0.5s ease,
      visibility 0.5s;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    border-radius: 20px;
    padding: rem(6px);
    transition: background 50ms linear;

    @media (max-width: 650px) {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .socialNetworksContainer {
    position: absolute;
    width: 90%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(72px);
    bottom: 0;
    padding: 6px rem(16px);
    border-radius: 8px;
    background: #282631;

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 18%;
      border-left: rem(12px) solid transparent;
      border-right: rem(12px) solid transparent;
      border-top: rem(12px) solid #282631;
      width: 0;
      height: 0;
    }
    .socials {
      display: flex;
      flex-direction: row;
      gap: rem(12px);
    }
    .iconSocial {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .fullScreenContainer {
    top: 0;
    right: 0;
    margin: rem(30px);
    border-radius: 20px;
  }
}

.cardAct {
  display: flex;
  gap: rem(12px);
}

.iconSetting {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsEnter {
  opacity: 0;
  transform: scale(0);
}

.settingsEnterActive {
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 100ms ease-in-out,
    transform 200ms ease-in-out;
}

.settingsExit {
  opacity: 1;
}

.settingsExitActive {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.part {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: rem(12px);

  .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    width: rem(27px);
    height: rem(27px);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    border: rem(3px) solid #383744;
    box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:checked {
      border: rem(3px) solid #fff;
      background-image: url('../icons/CheckboxTickIcon.svg');
      box-shadow: 0 rem(14px) rem(32px) 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.archiveTag {
  width: 100%;
  max-width: fit-content;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: rem(90px);

  .textStyle {
    display: flex;
    position: relative;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background: linear-gradient(
      127deg,
      #9e5992 0%,
      #6a19ab 37.27%,
      #7250d2 71.76%
    );
    z-index: 1;

    &:before {
      content: '';
      border-radius: 200px;
      z-index: 1;
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      background: #282631;
    }

    .texts {
      z-index: 99;
    }
  }
}
