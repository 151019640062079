@import '~styles/mixins';

.heading {
  color: rgba(235, 235, 245, 0.3);
  display: flex;
  align-items: flex-start;
  margin-top: rem(20px);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input {
  margin-top: rem(11px);
}
