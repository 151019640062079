@import '~styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: var(--height, 100vh);
  background-color: rgba(19, 17, 28, 0.4);
  opacity: 0;
  backdrop-filter: blur(0);
  pointer-events: none;
  z-index: 1000;
  transition: all 0.3s;

  &.wrapperOpen {
    opacity: 1;
    backdrop-filter: blur(30px);
    pointer-events: all;
  }

  &.isScroll {
    align-items: flex-start;
    overflow: scroll;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: rem(22px);
    max-width: rem(660px);
    width: 100%;
    padding: rem(44px);
    background: #13111c;
    border: 2px solid #1f1d28;
    border-radius: rem(14px);

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: rem(22px);
      .title {
        font-weight: 600;
        font-size: rem(22px);
      }

      .closeButton {
        display: flex;
        align-items: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: rem(26px);

      &Lists {
        display: flex;
        flex-direction: column;
        gap: rem(12px);

        .list {
          display: flex;
          align-items: center;
          gap: rem(12px);
          font-weight: 500;
          font-size: rem(18px);
          color: #d1d1d6;
        }
      }

      &Price {
        display: flex;
        flex-direction: column;
        gap: rem(12px);

        &Title {
          color: #d1d1d6;
        }

        &Lists {
          display: flex;
          flex-direction: column;
          gap: rem(12px);

          .list {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(12px);
            width: 100%;
            height: rem(56px);
            padding: rem(6px) rem(22px);
            font-weight: 500;
            font-size: rem(18px);
            line-height: 122%;
            border-radius: rem(6px);
            background: #1e1c26;
            box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
            transition: all 0.3s;
            cursor: pointer;

            @media (max-width: 410px) {
              height: max-content;
            }

            &.active {
              outline: 1px solid #fff;
            }

            .priceWrapper {
              display: flex;
              flex-direction: column;
              text-align: end;

              .priceTips {
                font-weight: 400;
                font-size: rem(16px);
                color: rgba(255, 255, 255, 0.7);
              }
            }

            .popularTips {
              position: absolute;
              right: 10px;
              top: -8px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: rem(92px);
              height: rem(18px);
              padding: 0px rem(5px);
              font-weight: 500;
              font-size: rem(11px);
              line-height: normal;
              background: linear-gradient(
                90deg,
                #9e5992 0%,
                #6a19ab 50.52%,
                #3054bb 100%
              );
              border-radius: rem(200px);
            }
          }
        }
      }

      &Links {
        display: flex;
        justify-content: center;
        align-content: center;
        gap: rem(12px);

        .button {
          max-width: rem(250px);
          width: 100%;
          border-radius: rem(200px);
          padding: 0px rem(22px);
          height: rem(56px);
          font-size: rem(18px);
          background: #1e1c26;
          box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
