@import '~styles/mixins';

.gridWrapper {
  width: 100%;
}

.loaderWrapper {
  width: 100%;
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}
