@import '~styles/mixins';

.container {
  position: absolute;
  top: 130%;
  right: 0;
  z-index: 1000;
  height: 85vh;
  min-width: rem(420px);
  max-width: rem(520px);
  border-radius: 24px;
  padding: 20px;
  border: 2px solid #1f1d28;
  background: #13111c;
  overflow-y: scroll;
  box-shadow: 0 0 rem(22px) 0 rgba(0, 0, 0, 0.15);

  @media (max-width: 930px) {
    position: fixed;
    z-index: 9999999;
    height: calc(100% - 100px);
    max-width: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }

  .headerModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(16px);
    @media (max-width: 640px) {
      display: none;
    }

    .heading {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.navigationPanel {
  padding: rem(27px) 0 rem(26px) 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 500;
    font-size: 18px;

    line-height: 22px;
    color: #ffffff;
  }
}

.notifications {
  .notifications__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    align-items: center;
    gap: rem(25px);
    width: 100%;

    .group {
      margin-top: rem(16px);
      color: #fff;
      align-self: start;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.loaderWrapper {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Полупрозрачный фон
  backdrop-filter: blur(10px); // Эффект размытия
  z-index: 999; // Убедитесь, что оверлей находится под модальным окном
  display: none; // Скрыт по умолчанию
}

.overlayActive {
  display: block; // Показать оверлей, когда модальное окно активно
}
